import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./assets/style/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import AdminLogin from "./pages/AdminLogin";
import SelfService from "./pages/SelfService";
import AccessCode from "./pages/AccessCode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "./context/AppContext";

function App() {
  const pathName = useLocation().pathname;

  const { isLogin } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    navigate(isLogin ? "/" : "/Login");
  }, [isLogin]);

  return (
    <div>
      <ToastContainer />
      <div>
        {pathName === "/" && <SelfService />}

        <Routes>
          <Route exact path="/Login" element={<AdminLogin />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
