import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "../assets/images/search-lg-icon.svg";
import filterLines from "../assets/images/filter-lines.svg";
import downloadIcon from "../assets/images/download-icon.svg";
import trashIcon from "../assets/images/trash-icon.svg";
import plusIcon from "../assets/images/plus-icon.svg";
import threeDots from "../assets/images/dots-vertical.svg";

const JobApplicants = () => {
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File selected:", file.name);
      // You can add further logic to handle the file upload here
    }
  };
  const [rowsToShow, setRowsToShow] = useState(10);

  const handleRowsToShowChange = (event) => {
    setRowsToShow(Number(event.target.value));
  };
  const JobApplicantsData = [
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "1st July 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Full-Stack Development",
      "Data Science",
      "80%",
      "70%",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "15th June 2024",
      "2 weeks",
      "No",
      "₹70,000",
      "Project Management",
      "Structural Analysis",
      "85%",
      "65%",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "10th August 2024",
      "3 months",
      "Yes",
      "₹90,000",
      "Quantum Mechanics",
      "Particle Physics",
      "90%",
      "75%",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "20th June 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Data Analysis",
      "Machine Learning",
      "88%",
      "77%",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "1st July 2024",
      "2 weeks",
      "No",
      "₹95,000",
      "Financial Modelling",
      "Risk Management",
      "92%",
      "80%",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "1st July 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Full-Stack Development",
      "Data Science",
      "80%",
      "70%",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "15th June 2024",
      "2 weeks",
      "No",
      "₹70,000",
      "Project Management",
      "Structural Analysis",
      "85%",
      "65%",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "10th August 2024",
      "3 months",
      "Yes",
      "₹90,000",
      "Quantum Mechanics",
      "Particle Physics",
      "90%",
      "75%",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "20th June 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Data Analysis",
      "Machine Learning",
      "88%",
      "77%",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "1st July 2024",
      "2 weeks",
      "No",
      "₹95,000",
      "Financial Modelling",
      "Risk Management",
      "92%",
      "80%",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "1st July 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Full-Stack Development",
      "Data Science",
      "80%",
      "70%",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "15th June 2024",
      "2 weeks",
      "No",
      "₹70,000",
      "Project Management",
      "Structural Analysis",
      "85%",
      "65%",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "10th August 2024",
      "3 months",
      "Yes",
      "₹90,000",
      "Quantum Mechanics",
      "Particle Physics",
      "90%",
      "75%",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "20th June 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Data Analysis",
      "Machine Learning",
      "88%",
      "77%",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "1st July 2024",
      "2 weeks",
      "No",
      "₹95,000",
      "Financial Modelling",
      "Risk Management",
      "92%",
      "80%",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "1st July 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Full-Stack Development",
      "Data Science",
      "80%",
      "70%",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "15th June 2024",
      "2 weeks",
      "No",
      "₹70,000",
      "Project Management",
      "Structural Analysis",
      "85%",
      "65%",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "10th August 2024",
      "3 months",
      "Yes",
      "₹90,000",
      "Quantum Mechanics",
      "Particle Physics",
      "90%",
      "75%",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "20th June 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Data Analysis",
      "Machine Learning",
      "88%",
      "77%",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "1st July 2024",
      "2 weeks",
      "No",
      "₹95,000",
      "Financial Modelling",
      "Risk Management",
      "92%",
      "80%",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "1st July 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Full-Stack Development",
      "Data Science",
      "80%",
      "70%",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "15th June 2024",
      "2 weeks",
      "No",
      "₹70,000",
      "Project Management",
      "Structural Analysis",
      "85%",
      "65%",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "10th August 2024",
      "3 months",
      "Yes",
      "₹90,000",
      "Quantum Mechanics",
      "Particle Physics",
      "90%",
      "75%",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "20th June 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Data Analysis",
      "Machine Learning",
      "88%",
      "77%",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "1st July 2024",
      "2 weeks",
      "No",
      "₹95,000",
      "Financial Modelling",
      "Risk Management",
      "92%",
      "80%",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "1st July 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Full-Stack Development",
      "Data Science",
      "80%",
      "70%",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "15th June 2024",
      "2 weeks",
      "No",
      "₹70,000",
      "Project Management",
      "Structural Analysis",
      "85%",
      "65%",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "10th August 2024",
      "3 months",
      "Yes",
      "₹90,000",
      "Quantum Mechanics",
      "Particle Physics",
      "90%",
      "75%",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "20th June 2024",
      "1 month",
      "Yes",
      "₹80,000",
      "Data Analysis",
      "Machine Learning",
      "88%",
      "77%",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "1st July 2024",
      "2 weeks",
      "No",
      "₹95,000",
      "Financial Modelling",
      "Risk Management",
      "92%",
      "80%",
    ],
  ];

  return (
    <div className="candListBox ">
      <div className="candListHdr">
        <div className="canLisTxtStyl">
          <p>Job Applicants</p>
        </div>
        <div className="canLisSearchArea">
          <div className="searchBox">
            <img src={SearchIcon} className="boxIcon" alt="Logo" />
            <input
              type="text"
              className="searchInput"
              placeholder="Search"
              // readOnly
            />
          </div>
          {/* <div className="canLisIconBox">
            <img src={filterLines} className="boxIcon" alt="Logo" />
          </div> */} 
          {/* <div className="canLisIconBox">
            <img src={downloadIcon} className="boxIcon" alt="Logo" />
          </div>
          <div className="canLisIconBox">
            <img src={trashIcon} className="boxIcon" alt="Logo" />
          </div> */}
          {/* <button className="uploadBtn" onClick={handleUploadClick}>
            <img src={plusIcon} className="boxIcon" alt="Logo" />
            <p>Upload</p>
            <input
              type="file"
              className="fileInput"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept=".pdf,.doc,.docx"
            />
          </button> */}
        </div>
      </div>
      <div className="table-responsive tableWrap">
        <table className="customTable">
          <thead>
            <tr>
              {/* <th className="rowTxtChkBxAC">
              <input type="checkbox" className="customCheckbox" /> Sr.
              No.
            </th> */}
              <th>Date of Completion</th>
              <th>Name</th>
              <th>Education Level</th>
              <th>Field of Education</th>
              <th>Years of Experience</th>
              <th>Current Company</th>
              <th>Current Job</th>
              <th>Current Industry</th>
              <th>Available from</th>
              <th>Notice Period</th>
              <th>Buy Out Option Available</th>
              <th>Expected Salary</th>
              <th>Primary Profile</th>
              <th>Secondary Profile</th>
              <th>Primary Match</th>
              <th>Secondary Match</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {JobApplicantsData.slice(0, rowsToShow).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {/* <td className="rowTxtChkBx nameCell">
                <input type="checkbox" className="customCheckbox" />
                <span className="nameBold">{row[0]}</span>
              </td> */}
                {row.slice(0).map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
                <td>
                  <img src={threeDots} className="boxIcon" alt="Logo" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="rowSelectorArea">
        <div className="rowSelector">
          <label htmlFor="rowsToShow">Show rows:</label>
          <select
            id="rowsToShow"
            value={rowsToShow}
            onChange={handleRowsToShowChange}
          >
            {[...Array(10).keys()]
              .map((i) => (i + 1) * 10)
              .map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default JobApplicants;
