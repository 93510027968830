const BASE_URL = "https://api-beta.lywo.in/"; /* Stage */

export const ApiConfig = {
  ACCESS_CODE_LIST: BASE_URL + "api/CandidateMgmt/AdminUniqueCodeUserdtls?",
  GET_OTP_WITH_EMAIL: BASE_URL + "api/Account/AdminAuthorizeEmail",
  LOGIN_WITH_VALID_OTP: BASE_URL + "api/Account/AdminValidatedOTP",
  DOWNLOAD_EXCEL:
    BASE_URL + "api/CandidateMgmt/ExportUniqueCodeUserDetails?code=", // pass code
  GROUP_LIST: BASE_URL + "api/CandidateMgmt/GetCodeAccessDtls?email=",
  ADD_JOB: BASE_URL + "api/Job/Insertjobcode",
  GET_CODE_DETAILS: BASE_URL + "api/Job/GetJobCodeDetails?jobcode=",
  DELETE_USER_AND_DEP: BASE_URL + "api/User/DeleteUserAndDependencies?",
  EXCELDOWNLOADFILE: BASE_URL + "api/Assessment/GenerateExcel/generateExcel?UserId="
};
