import React, { useContext, useEffect, useState } from "react";
import LYWOLogoSmall from "../assets/images/LYWOLogoSmall.svg";
import Illustration from "../assets/images/Illustration.svg";
import key from "../assets/images/keyLog.svg";
import arrowRight from "../assets/images/arrow-right.svg";
import emailIcon from "../assets/images/EmailIcon.svg";
import { useNavigate } from "react-router-dom";
import {
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from "../Api/ApiServices";
import { ApiConfig } from "../Api/ApiConfig";
import { toastMsg } from "../component/toast";

import { Modal, Button, Form } from "react-bootstrap";

import { AppContext } from "../context/AppContext";

const AdminLogin = () => {
  const navigate = useNavigate();

  const { setIsLogin } = useContext(AppContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [otpError, setOtpError] = useState(false); // new state for OTP error

  const [otpSend, setOtpSend] = useState(false);

  const [loginButtonDisable, setLoginButtonDisable] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = () => {
    const otpString = otp.join("");
    setEmailFormatError(false); // Reset email error state
    setOtpError(false); // Reset OTP error state

    if (
      email.trim() === "" ||
      otpString.trim() === "" ||
      otpString.length !== 6 ||
      !emailRegex.test(email)
    ) {
      if (email.trim() === "" || !emailRegex.test(email)) {
        setEmailFormatError(true);
      }
      if ((otpSend && otpString.trim() === "") || otpString.length !== 6) {
        setOtpError(true);
      }
    } else {
      if (otpSend) {
      } else {
        getOtpWithEmail();
      }
    }
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    setOtpError(false); // Reset OTP error on change

    // Focus the next input

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const getOtpWithEmail = () => {
    if (!emailRegex.test(email) || email.trim() === "") {
      setEmailFormatError(true);

      return;
    }

    setLoginButtonDisable(true);

    simplePostCall(
      ApiConfig.GET_OTP_WITH_EMAIL,
      JSON.stringify({
        email: email,
      })
    )
      .then((res) => {
        if (res?.succeded) {
          setOtpSend(true);

          toastMsg("success", "OTP successfully sended on your email");

          localStorage.setItem(
            "admin",
            JSON.stringify(res?.response?.companyName)
          );
          localStorage.setItem("email", JSON.stringify(email));
        } else {
          toastMsg("error", res?.message);
        }

        // toastMsg('error',res)

        setLoginButtonDisable(false);
      })
      .catch((err) => {
        console.log(err);

        setLoginButtonDisable(false);
      });
  };

  const LoginWitOtp = () => {
    const otpString = otp.join("");

    if ((otpSend && otpString.trim() === "") || otpString.length !== 6) {
      setOtpError(true);

      return;
    }

    setLoginButtonDisable(true);

    simplePostCall(
      ApiConfig.LOGIN_WITH_VALID_OTP,
      JSON.stringify({
        email: email,
        otp: otp.join(""),
      })
    )
      .then((res) => {
        if (res?.succeded) {
          localStorage.setItem("userId", JSON.stringify(res?.response?.userId));
          localStorage.setItem(
            "auth_token",
            JSON.stringify(res?.response?.token)
          );

          setIsLogin(true);

          toastMsg("success", "Login Successfully");
        } else {
          toastMsg("error", res?.errors);
        }

        setLoginButtonDisable(false);
      })
      .catch((err) => {
        console.log(err);

        setLoginButtonDisable(false);
      });
  };

  return (
    <div className="clWrap">
      <div className="clLft-side">
        <div className="clLogoStyleMob">
          <a href="/">
            <img src={LYWOLogoSmall} className="clLogoStyle" alt="Logo" />
          </a>
        </div>
        <div className="illustrationContainer">
          <img src={Illustration} className="illustImg" alt="Logo" />
        </div>
      </div>
      <div id="adminLog" className="formSpc">
        <div className="loginCode">
          <div className="text-center">
            <p>
              Welcome to <b>LYWO</b>
              <br />
              Organization User Login
            </p>
          </div>
          <div className="formArea">
            <div
              id="emailBox"
              className={`mb-3 ${emailFormatError ? "error" : ""}`}
            >
              <label htmlFor="emailInput">EMAIL ID</label>
              <div className="input-group">
                <img
                  src={emailIcon}
                  className="emailIconStyl"
                  alt="Email Icon"
                />
                <input
                  type="text"
                  id="emailInput"
                  name="emailInput"
                  placeholder="Enter Your Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailFormatError(false);
                  }}
                  className={emailFormatError ? "error" : ""}
                  required
                />
              </div>
              {emailFormatError && (
                <p className="instructionTxtError">
                  Please enter a valid email.
                </p>
              )}
            </div>
            {otpSend && (
              <div id="otpBox" className={`mb-3 ${otpError ? "error" : ""}`}>
                <label htmlFor="otpInput">OTP</label>
                <div
                  className="input-group"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {otp.map((data, index) => (
                    <input
                      key={index}
                      type="text"
                      name="otp"
                      maxLength="1"
                      value={data}
                      onPaste={(e) => {
                        const text = navigator.clipboard
                          .readText()
                          .then((res) => {
                            const data = res?.substring(0, 6)?.split("");
                            setOtp(data);
                          });
                        console.log(text);
                      }}
                      onChange={(e) => {
                        handleChange(e.target, index);
                      }}
                      onFocus={(e) => e.target.select()}
                      required
                      style={{
                        width: "2.5rem",
                        height: "2.5rem",
                        margin: "0",
                        textAlign: "center",
                      }}
                    />
                  ))}
                </div>
                {otpError ? (
                  <p className="instructionTxtError">
                    Please enter a valid 6-digit OTP.
                  </p>
                ) : (
                  <p className="instructionTxt">
                    Please enter the OTP you have.
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="btnArea">
            <button
              className="btnVldt"
              onClick={otpSend ? LoginWitOtp : getOtpWithEmail}
              disabled={loginButtonDisable}
              // disabled={!email || !otp}
            >
              <p>LOGIN</p>
              {/* <img
                src={arrowRight}
                className="arrowIconStyl"
                alt="ArrowRight-Icon"
              /> */}
            </button>
          </div>
          {/* <div className="regstrArea">
            <p>Not registered?</p>
            <Button variant="link" onClick={handleShow}>
              Register Now
            </Button>

            <Modal
              show={show}
              onHide={handleClose}
              dialogClassName="custom-modal"
            >
              <Modal.Header closeButton className="custom-close-btn">
                <Modal.Title className="custom-modal-title">
                  Registration Form
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="firstName" className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="First Name" />
                  </Form.Group>
                  <Form.Group controlId="lastName" className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Last Name" />
                  </Form.Group>
                  <Form.Group controlId="emailId" className="mb-3">
                    <Form.Label>Email id</Form.Label>
                    <Form.Control type="email" placeholder="Email id" />
                  </Form.Group>
                  <Form.Group controlId="phoneNumber" className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="text" placeholder="Phone Number" />
                  </Form.Group>
                  <Form.Group
                    controlId="organizationOrIndividualName"
                    className="mb-3"
                  >
                    <Form.Label>Organization / Individual Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Organization / Individual Name"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  className="custom-register-btn"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Register
                </Button>
              </Modal.Footer>
            </Modal>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
