import React, { useContext, useEffect, useRef, useState } from "react";
import LYWOLogoSmall from "../assets/images/LYWOLogoSmall.svg";
import CandidatesLogo from "../assets/images/CandidatesLogo.svg";
import jobApplicantsLogo from "../assets/images/jobApplicants.svg";
import activeJobApplicantsLogo from "../assets/images/activeJobApplicants.svg";
import ActiveCandidatesLogo from "../assets/images/ActiveCandidatesLogo.svg";
import AccessCodeLogo from "../assets/images/AccessCodeLogo.svg";
import ActiveAccessCodeLogo from "../assets/images/ActiveAccessCodeLogo.svg";
import ArrowCircleLeftLogo from "../assets/images/arrow-circle-left.svg";
import NotificationIconTop from "../assets/images/notification-icon.svg";
import SettingsMachineTop from "../assets/images/settings-machine.svg";
import HelpQuestionTop from "../assets/images/help-question.svg";
import ProfileDropdown from "../assets/images/profile-Dropdown.svg";
import BarIconTop from "../assets/images/bar-icon-top.svg";
import OnlineIndicator from "../assets/images/online-indicator.svg";
import UserDropDown from "../assets/images/user-dropDown.svg";
import settingsDropdown from "../assets/images/settingsDropdown.svg";
import zap from "../assets/images/zap.svg";
import homeLine from "../assets/images/home-line.svg";
import usersDropDwn from "../assets/images/users-dropDwn.svg";
import userPlus from "../assets/images/user-plus.svg";
import layersTwo from "../assets/images/layers-two.svg";
import messageSmileCircle from "../assets/images/message-smile-circle.svg";
import helpCircleDropdwn from "../assets/images/help-circleDropdwn.svg";
import containerDropDwn from "../assets/images/container.svg";
import logOut from "../assets/images/log-out.svg";
import JobApplicants from "./JobApplicants";
import InternalAssessment from "./InternalAssessment";
import AccessCode from "./AccessCode";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import GroupList from "./grouplist";
import arrowRight from "../assets/images/arrow-right-pagination.svg";
import { Form, Modal } from "react-bootstrap";

const SelfService = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { setIsLogin, setAdminData, adminInitialData } = useContext(AppContext);

  const [showCodeRespondent, setShowCodeRespondent] = useState("");

  const [companyDtls, setCompnayDtls] = useState({
    company: "",
    designation: "",
    position: "",
  });

  const [openSideBar, setOpenSideBar] = useState(true);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // active menu
  const [activeMenu, setActiveMenu] = useState("grouplist");

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const [show, setShow] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();

    setIsLogin(false);

    setAdminData(adminInitialData);

    navigate("/Login");
  };
  const [code, setCode] = useState("");
  
  return (
    <div className="canLisWrap">
      <div
        className="canLisSideBar d-none"
        style={openSideBar ? null : { display: "none" }}
      >
        <div className="canLisLogoArea">
          <a href="/">
            <img src={LYWOLogoSmall} className="canLisLogoStyle" alt="Logo" />
          </a>
        </div>
        <div className="sideBarMenuArea">
          {/*
          <div
            className={`sideBarMenu ${
              activeMenu === "jobApplicants" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("jobApplicants")}
          >
            <img
              src={
                activeMenu === "jobApplicants"
                  ? activeJobApplicantsLogo
                  : jobApplicantsLogo
              }
              className="sideBarLogoStyle"
              alt="Logo"
            />
            <p>Job Applicants</p>
          </div>
          <div
            className={`sideBarMenu ${
              activeMenu === "internalAssessment" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("internalAssessment")}
          >
            <img
              src={
                activeMenu === "internalAssessment"
                  ? ActiveCandidatesLogo
                  : CandidatesLogo
              }
              className="sideBarLogoStyle"
              alt="Logo"
            />
            <p>Internal Assessment</p>
          </div>
          */}
          <div
            className={`sideBarMenu ${
              activeMenu === "respondentData" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("respondentData")}
          >
            <img
              src={
                activeMenu === "respondentData"
                  ? ActiveAccessCodeLogo
                  : AccessCodeLogo
              }
              className="sideBarLogoStyle"
              alt="Logo"
            />
            <p>Respondent Data</p>
          </div>
          <div
            className={`sideBarMenu ${
              activeMenu === "grouplist" ? "active" : ""
            }`}
            onClick={() => handleMenuClick("grouplist")}
          >
            <img
              src={
                activeMenu === "grouplist"
                  ? ActiveAccessCodeLogo
                  : AccessCodeLogo
              }
              className="sideBarLogoStyle"
              alt="Logo"
            />
            <p>Group Lists</p>
          </div>
        </div>
        <div className="sideBarBottomBtn">
          <img
            src={ArrowCircleLeftLogo}
            className="sideBarLogoStyle"
            alt="Logo"
            onClick={() => setOpenSideBar(false)}
          />
        </div>
      </div>
      <div className={`canLisContentWrap ${!openSideBar ? "fullwidth" : null}`}>
        <div className="canLisNavBar">
          <div className="OrgArea">
            {openSideBar ? null : (
              <div>
                <img src={arrowRight} onClick={() => setOpenSideBar(true)} />
              </div>
            )}
             <a href="/">
              <img src={LYWOLogoSmall} className="canLisLogoStyle" alt="Logo" />
            </a>
            {/* <div className="OrgLogo">
            <a href="/">
              <img src={LYWOLogoSmall} className="canLisLogoStyle" alt="Logo" />
            </a>
            </div>
            <p>
              {localStorage.getItem("admin") &&
              JSON.parse(localStorage.getItem("admin")) == "Admin"
                ? "LYWO"
                : JSON.parse(localStorage.getItem("admin"))}
            </p> */}
            <p className="ms-5 mt-auto">
            <nav>
                <ol className="breadcrumb" style={{marginBottom:"7px"}}>
                {/* <li className="breadcrumb-item" style={{cursor:"pointer"}} onClick={() => handleMenuClick("respondentData")}>
                    Respondent Data
                 </li> */}
                  <li className="breadcrumb-item" style={{cursor:"pointer"}} onClick={() => handleMenuClick("grouplist")}>
                 All Jobs
                  </li>
                  <li className="breadcrumb-item bread-active">
                    { showCodeRespondent }
                    </li>
                 
                </ol>
              </nav>
            </p>
            <div className="d-flex align-items-center">
             
            </div>
          </div>
          <div className="profileArea">
            <h5>{`${companyDtls?.designation ? companyDtls?.designation : "Admin"}`}</h5>
            <div>
              <img
                src={logOut}
                className="profileOptnIcon"
                alt="logo"
                onClick={handleLogout}
              />
            </div>
            {/*
            <div className="profileBtnArea">
              <img
                src={NotificationIconTop}
                className="profileBtnStyle"
                alt="Logo"
              />
              <img
                src={SettingsMachineTop}
                className="profileBtnStyle"
                alt="Logo"
              />
              <img
                src={HelpQuestionTop}
                className="profileBtnStyle"
                alt="Logo"
              />
            </div>
            */}
            {/* 
            <div className="profileDropDown" ref={dropdownRef}>
              <img
                src={ProfileDropdown}
                className={`profileImgStyle ${isOpen ? "active" : ""}`}
                alt="Logo"
                onClick={toggleDropdown}
              />
              {isOpen && (
                <div className="dropdownMenu">
                  <div className="dropdownMenuProfile">
                    <div className="profImgContainer">
                      <img
                        src={ProfileDropdown}
                        className="profileImgStyle"
                        alt="Profile Dropdown"
                      />
                      <img
                        src={OnlineIndicator}
                        className="OnlineIndicatorStyle"
                        alt="Online Indicator"
                      />
                    </div>
                    <div className="profTxt">
                      <p className="hdProfTxt">Olivia Rhye</p>
                      <p className="subProfTxt">olivia@untitledui.com</p>
                    </div>
                  </div>
                  <div className="dropdownMenuOptionBox">
                    {/*
                    <div className="optnBtnArea">
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={UserDropDown}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>View profile</p>
                          </div>
                          <p>⌘K->P</p>
                        </div>
                      </a>
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={settingsDropdown}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>Settings</p>
                          </div>
                          <p>⌘S</p>
                        </div>
                      </a>
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={zap}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>Keyboard shortcuts</p>
                          </div>
                          <p>?</p>
                        </div>
                      </a>
                    </div>
                    <div className="optnBtnArea">
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={homeLine}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>Company profile</p>
                          </div>
                          <p>⌘K->C</p>
                        </div>
                      </a>
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={usersDropDwn}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>Team</p>
                          </div>
                          <p>⌘K->T</p>
                        </div>
                      </a>
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={userPlus}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>Invite colleagues</p>
                          </div>
                          <p>⌘I</p>
                        </div>
                      </a>
                    </div>
                    <div className="optnBtnArea">
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={layersTwo}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>Changelog</p>
                          </div>
                          <p>⌘K->C</p>
                        </div>
                      </a>
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={messageSmileCircle}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>Slack Community</p>
                          </div>
                          <p>⌘K->S</p>
                        </div>
                      </a>
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={helpCircleDropdwn}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>Support</p>
                          </div>
                          <p>⌘/</p>
                        </div>
                      </a>
                      <a href="/">
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={containerDropDwn}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>API</p>
                          </div>
                          <p>⌘A</p>
                        </div>
                      </a>
                    </div>
                    
                    <div className="optnBtnArea" style={{ borderBottom: 0 }}>
                      <a onClick={handleLogout}>
                        <div className="optnInside">
                          <div className="optnLogoTxt">
                            <img
                              src={logOut}
                              className="profileOptnIcon"
                              alt="logo"
                            />
                            <p>Log out</p>
                          </div>
                          <p>⌥⇧Q</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="BarIconTopArea">
              <img src={BarIconTop} className="profileBtnStyle" alt="Logo" />
            </div>
            */}
          </div>
        </div>
        {activeMenu === "jobApplicants" && <JobApplicants />}
        {activeMenu === "internalAssessment" && <InternalAssessment />}
        {activeMenu === "respondentData" && (
          <AccessCode
            respondendByCode={showCodeRespondent}
            companyDtls={companyDtls}
          />
        )}
        {activeMenu === "grouplist" && (
          <GroupList
            codeRespondendHandler={(code, designation, company, form) => {
              setShowCodeRespondent(code);
              setActiveMenu("respondentData");
              setCompnayDtls({
                company: company,
                designation: designation,
                position: form,
              });
            }}
            clearCode={() => {
              setShowCodeRespondent("");
              setCompnayDtls({
                company: "",
                designation: "",
                position: "",
              });
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Body>
          <p className="text-center mb-4 " style={{ fontSize: "23px" }}>
            Are you sure to Logout ?
          </p>
          <hr />
          <Form>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-white border mx-2"
                onClick={handleClose}
              >
                cancel
              </button>
              <button className="btnVldt" onClick={handleLogout}>
                confirm
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SelfService;
