import React, { useContext, useEffect, useRef, useState } from "react";
import SearchIcon from "../assets/images/search-lg-icon.svg";
import filterLines from "../assets/images/filter-lines.svg";
import downloadIcon from "../assets/images/download-icon.svg";
import trashIcon from "../assets/images/trash-icon.svg";
import plusIcon from "../assets/images/plus-icon.svg";
import threeDots from "../assets/images/dots-vertical.svg";
import close from "../assets/images/x-close.svg";
import { Space, Table } from "antd";
import _ from "lodash";

import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../Api/ApiServices";
import { ApiConfig } from "../Api/ApiConfig";
import NoDataFound from "../component/noDataFound";

import {
  Modal,
  Button,
  Form,
  Select,
  option,
  FormControl,
  FormGroup,
} from "react-bootstrap";
import { toastMsg } from "../component/toast";
import { render } from "@testing-library/react";
import AppState from "../context/AppState";
import { AppContext } from "../context/AppContext";
import { hover } from "@testing-library/user-event/dist/hover";

const GroupList = (props) => {
  const { codeRespondendHandler, clearCode } = props;

  const [valiteded, setValiteded] = useState(false);

  const [checkStrictly, setCheckStrictly] = useState(false);

  const [isActive, setIsActive] = useState(true);
  const [isActivewin, setIsActivewin] = useState(false);

  const profilePer = {
    codeId: 0,
    id: 0,
    pioneer: "",
    influencer: "",
    teamPlayer: "",
    logicalThinker: "",
    persuader: "",
    achiever: "",
    perfectionist: "",
    collaborator: "",
    assessor: "",
    implementor: "",
    motivator: "",
    leader: "",
    administrator: "",
    mediator: "",
  };

  const initialState = {
    id: 0,
    accessCode: "",
    reason: "",
    company: "",
    designation: "",
    additionalQs1: "no",
    additionalQs2: "no",
    primaryMatch: "",
    secoundryMatch: "",
    primaryProfile: "",
    secoundryProfile: "",
    association: "",
    isActive: true,
  };

  const [details, setDetails] = useState(initialState);

  const { adminData } = useContext(AppContext);

  const [initialProfiles, setInitialProfiles] = useState(profilePer);

  const [isEdit, setIsEdit] = useState(false);

  const [show, setShow] = useState(false);
  const [reqfield, setReqfield] = useState(null);
  const handleClose = () => {
    setDetails(initialState);

    setEnterEmail("");

    setShowEmails([]);

    setIsEdit(false);

    setValiteded(false);

    setInitialProfiles(profilePer);

    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File selected:", file.name);
      // You can add further logic to handle the file upload here
    }
  };

  const [groupListData, setGroupListData] = useState([]);

  const [isLoadin, setIsLoading] = useState(true);

  const [rowsToShow, setRowsToShow] = useState(10);

  const handleRowsToShowChange = (event) => {
    setRowsToShow(Number(event.target.value));
  };

  const getData = () => {
    const url = `${ApiConfig.GROUP_LIST}${JSON.parse(
      localStorage.getItem("email")
    )}&IsActive=${isActive}`;
    simpleGetCallWithErrorResponse(url)
      .then((res) => {
        console.log(res);

        if (res?.json?.succeded) {
          setGroupListData(res?.json?.response);
          console.log(res?.json?.data?.length);
        } else {
          console.log("something went wrong");
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("something went wrong");

        setIsLoading(false);
      });
  };

  useEffect(() => {
    //codeRespondendHandler('')
    clearCode();
    getData();
  }, [isActive]);

  const copyCode = (code) => {
    navigator.clipboard.writeText(
      // "https://lywouserweb.app.cloudxperte.com/?code=" + code
      // "http://localhost:3000/?code=" + code
      "http://apply.lywo.in/?code=" + code
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() == false) {
      console.log(form.checkValidity());

      setValiteded(true);
    } else {
      if (details.accessCode.length != 7) {
        toastMsg("error", "please Enter 7 Digit Valid Access Code");
        return;
      }

      if (showEmails.length == 0 && details.reason === "Job Application") {
        toastMsg("error", "Please Add Users");
        return;
      }

      PostCallWithErrorResponse(ApiConfig?.ADD_JOB, {
        id: details?.id,
        isActive: details?.isActive,
        code: details?.accessCode,
        codeAssociation: details?.association,
        position: details?.designation,
        reason: details?.reason,
        company: details?.company,
        additionalPart1: details.additionalQs1,
        additionalPart2: details.additionalQs2,
        primaryId: details?.primaryProfile,
        secondaryId: details?.secoundryProfile,
        primaryMatch: details?.primaryMatch,
        secondaryMatch: details.secoundryMatch,
        profilePer: details.reason === "Job Application" ? initialProfiles : {},
        users: showEmails,
      })
        .then((res) => {
          console.log(res);

          if (res?.json?.succeded) {
            setIsEdit(false);
            toastMsg("success", res?.json?.response?.statusMessage);
            getData();
            handleClose();
          } else {
            toastMsg("error", res?.json?.response?.statusMessage);
          }
        })
        .catch((err) => {
          console.log("something wrong");
        });
    }
  };

  const paginationConfig = {
    pageSizeOptions: [
      "10",
      "20",
      "30",
      "40",
      "50",
      "60",
      "70",
      "80",
      "90",
      "100",
    ], // Options for items per page
    defaultPageSize: 50, // Default number of items per page
    showSizeChanger: false, // Show the dropdown to change page size
    onChange: (page, pageSize) => {
      console.log("Page:", page, "PageSize:", pageSize);
    },
  };

  const handleChage = (key, val) => {
    if (key === "reason") {
      let prt1 = "";
      let part2 = "";

      if (val === "Individual User" || val === "Investor") {
        prt1 = "no";
        part2 = "no";
      }

      if (val === "Job Application") {
        prt1 = "yes";
        part2 = "no";
      }

      if (val === "Internal Assessment") {
        prt1 = "no";
        part2 = "yes";
      }

      setDetails((prev) => ({
        ...prev,
        reason: val,
        additionalQs1: prt1,
        additionalQs2: part2,
      }));

      return;
    }

    setDetails((prev) => ({
      ...prev,
      [key]: val,
    }));
  };
  // const [enterEmail, setEnterEmail] = useState("");
  // const [showEmails, setShowEmails] = useState([]);
  // const [showed, setShowed] = useState(false);

  // const handleAddEmail = () => {
  //   setShowEmails([...showEmails, enterEmail]);
  //   setShowed(true);
  //   setEnterEmail("");
  // };
  const [enterEmail, setEnterEmail] = useState("");
  const [showEmails, setShowEmails] = useState([]);
  const [showed, setShowed] = useState(false);

  const handleAddEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (enterEmail.trim() !== "") {
      if (!emailRegex.test(enterEmail)) {
        toastMsg("error", "Please Enter Valid User Email");
        return;
      }

      let index = showEmails.findIndex((i) => i.email === enterEmail);

      if (index != -1) {
        toastMsg("error", "Email Already Added");
        return;
      }

      setShowEmails([
        ...showEmails,
        {
          codeId: 0,
          email: enterEmail,
        },
      ]);
      setShow(true);
      setEnterEmail("");
    }
  };

  const removeEmail = (indexToRemove) => {
    const updatedEmails = showEmails.filter(
      (_, index) => index !== indexToRemove
    );
    setShowEmails(updatedEmails);
  };

  const handleProfiles = (key, val) => {
    setInitialProfiles((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const filterData = (data) => (formatter) =>
    data.map((item) => ({
      text: formatter(item),
      value: formatter(item),
    }));
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const columns = [
    {
      title: "Access Code",
      render: (record) => <h5 className="link-text">{record}</h5>,
      dataIndex: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
      filters: _.uniqWith(
        filterData(groupListData)((i) => i.code),
        _.isEqual
      ),
      filteredValue: filteredInfo.code || null,
      filterSearch: true,
      onFilter: (value, record) =>
        record.code.toString().toLowerCase().includes(value.toLowerCase()),
      onCell: (record, rowIndex) => {
        return {
          onClick: (env) => {
            codeRespondendHandler(
              record?.code,
              record?.company,
              record?.designation,
              record?.reason
            );
          },
        };
      },
    },

    {
      title: "Form Type",
      dataIndex: "reason",
      sorter: (a, b) => a.reason?.localeCompare(b.reason),
      filters: _.uniqWith(
        filterData(groupListData)((i) => i.reason),
        _.isEqual
      ),
      filteredValue: filteredInfo.reason || null,
      filterSearch: true,
      onFilter: (value, record) =>
        record.reason.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Company",
      dataIndex: "company",
      sorter: (a, b) => a.company?.localeCompare(b.company),
      filters: _.uniqWith(
        filterData(groupListData)((i) => i.company),
        _.isEqual
      ),
      filteredValue: filteredInfo.company || null,
      filterSearch: true,
      onFilter: (value, record) =>
        value === ""
          ? record.company.toString().toLowerCase() === value.toLowerCase()
          : record.company
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation.localeCompare(b.designation),
      filters: _.uniqWith(
        filterData(groupListData)((i) => i.designation),
        _.isEqual
      ),
      filteredValue: filteredInfo.designation || null,
      filterSearch: true,
      onFilter: (value, record) =>
        value === ""
          ? record.designation.toString().toLowerCase() === value.toLowerCase()
          : record.designation
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
    },
    // {
    //   title: "Add'l Qs Part 1",
    //   dataIndex: "additionalPart1",
    //   sorter: (a, b) => a.additionalpart1 - b.additionalpart1,
    //   filters: _.uniqWith(
    //     filterData(groupListData)((i) => i.additionalPart1),
    //     _.isEqual
    //   ),
    //   filteredValue: filteredInfo.additionalPart1 || null,
    //   filterSearch: true,
    //   onFilter: (value, record) =>
    //     record.additionalPart1 ? record.additionalPart1.includes(value) : false,
    // },
    // {
    //   title: "Add'l Qs Part 2",
    //   dataIndex: "additionalPart2",
    //   sorter: (a, b) =>
    //     new Date(a.additionalpart2) - new Date(b.additionalPart2),
    //   filters: _.uniqWith(
    //     filterData(groupListData)((i) => i.additionalPart2),
    //     _.isEqual
    //   ),
    //   filteredValue: filteredInfo.additionalPart2 || null,
    //   filterSearch: true,
    //   onFilter: (value, record) =>
    //     record.additionalPart2 ? record.additionalPart2.includes(value) : false,
    // },
    // {
    //   title: "Applications",
    //   dataIndex: "completed",
    //   sorter: (a, b) => new Date(a.completed) - new Date(b.completed),
    //   filters: _.uniqWith(
    //     filterData(groupListData)((i) => i.completed),
    //     _.isEqual
    //   ),
    //   filteredValue: filteredInfo.completed  ,
    //   filterSearch: true,
    //   onFilter: (value, record) => record?.completed == value,
    // },
    {
      title: "Applications",
      dataIndex: ["completed", "respondent"], 
      sorter: (a, b) => new Date(a.completed) - new Date(b.completed),

      // filters: _.uniqWith(
      //   filterData(groupListData)((i) => i.completed), 
      //   _.isEqual
      // ).map((item) => ({ text: item, value: item })), 
      filteredValue: filteredInfo?.completed, 
      // filterSearch: false,
      // onFilter: (value, record) => {
      //   return record?.completed === value;
      // },
      render: (text, record) => {
        return `${record.completed} / ${record.respondent}`;
      },
    },
    {
      title: "Copy Code",
      render: (record) => (
        <div class="dropdown">
          <img
            src={threeDots}
            alt=""
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li
              class="dropdown-item"
              style={{ cursor: "pointer" }}
              onClick={() => copyCode(record?.code)}
            >
              Copy Link
            </li>
            <li
              class="dropdown-item"
              style={{ cursor: "pointer" }}
              onClick={() =>
                codeRespondendHandler(
                  record?.code,
                  record?.company,
                  record?.designation,
                  record?.reason
                )
              }
            >
              View Respondents
            </li>
            {localStorage.getItem("admin") &&
              JSON.parse(localStorage.getItem("admin")) == "Admin" && (
                <li
                  class="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => getEditData(record.code)}
                >
                  Edit
                </li>
              )}
          </div>
        </div>
      ),
    },
  ];
  const [checked, setChecked] = useState(true);
  const handleClickcheck = () => {
    setChecked(!checked);
  };

  const toggleSwitch = () => {
    setIsActive(!isActive);
  };
  const toggleSwitchWindow = () => {
    setIsActivewin(!isActivewin);
  };

  const getEditData = (code) => {
    setIsEdit(true);


    simpleGetCallWithErrorResponse(ApiConfig.GET_CODE_DETAILS + code).then(
      (res) => {
        console.log(res);
        
        if (res?.json?.succeded) {
          setDetails({
            isActive: res?.json?.response?.isActive,
            id: res?.json?.response?.id,
            accessCode: code,
            reason: res?.json?.response?.reason,
            company: res?.json?.response?.company,
            designation: res?.json?.response?.position,
            additionalQs1: res?.json?.response?.additionalPart1,
            additionalQs2: res?.json?.response?.primaryMatch,
            primaryMatch: res?.json?.response?.primaryMatch,
            secoundryMatch: res?.json?.response?.secondaryMatch,
            primaryProfile: res?.json?.response?.primaryId,
            secoundryProfile: res?.json?.response?.secondaryId,
            association: res?.json?.response?.codeAssociation,
          });


          handleShow();

          if (res?.json?.response?.reason === "Job Application") {
            setShowEmails(res?.json?.response?.users);

            if (res?.json?.response?.profilePer) {
              setInitialProfiles(res?.json?.response?.profilePer);
            }
          }
        } else {
          toastMsg("error", res?.json?.message);
        }
      }
    );
  };

  return (
    <>
      <div
        className="d-flex justify-content-end"
        style={{ marginTop: "25px", paddingRight: "25px" }}
      >
        <div className="col my-auto">
          <div className="App-header">
            <div
              className="row justify-content-end me-5 "
              onClick={toggleSwitch}
            >
              <div className="col-2 text-nowrap toggle-label text-end">
                Inactive Only
              </div>
              <div
                className={`col-4 toggle-switch ${isActive ? "active" : ""}`}
              >
                <div className="toggle-circle"></div>
              </div>
              <div
                className="col-1 text-nowrap toggle-label "
                style={{ marginLeft: "-12px" }}
              >
                Active Only
              </div>
            </div>
          </div>
        </div>

        {localStorage.getItem("admin") &&
          JSON.parse(localStorage.getItem("admin")) == "Admin" && (
            <button className="ml-4 btnVldt" onClick={handleShow}>
              Add Group
            </button>
          )}
      </div>
      <div className="candListBox ">
        <div className="candListHdr">
          <div className="canLisTxtStyl">
            <p>Group List</p>
          </div>

          <div>
            <Table
              size="large"
              columns={columns}
              className="grouplisttable-alljobs"
              dataSource={groupListData}
              onChange={handleChange}
              pagination={paginationConfig}
            ></Table>
          </div>

          {/* <div className="canLisSearchArea">
          <div className="searchBox">
            <img src={SearchIcon} className="boxIcon" alt="Logo" />
            <Form.Control
              type="text"
              className="searchForm.Control"
              placeholder="Search"
              // readOnly
            />
          </div>
          <div className="canLisIconBox">
            <img src={filterLines} className="boxIcon" alt="Logo" />
          </div>
          <div className="canLisIconBox">
            <img src={downloadIcon} className="boxIcon" alt="Logo" />
          </div>
          <div className="canLisIconBox">
            <img src={trashIcon} className="boxIcon" alt="Logo" />
          </div>
          {/* <button className="uploadBtn" onClick={handleUploadClick}>
            <img src={plusIcon} className="boxIcon" alt="Logo" />
            <p>Upload</p>
            <Form.Control
              type="file"
              className="fileForm.Control"
              ref={fileForm.ControlRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept=".pdf,.doc,.docx"
            />
          </button> }
        </div> */}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="custom-modal"
        size={`${details.reason === "Job Application" ? "xl" : "lg"}`}
      >
        <Modal.Header closeButton className="custom-close-btn">
          <Modal.Title className="custom-modal-title">
            Add/ Edit Group
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={valiteded} onSubmit={handleSubmit}>
            <div className="row">
              <div
                className={`${
                  details.reason === "Job Application" ? "col-6" : "col-12"
                }`}
              >
                <div className="d-flex align-items-center">
                  <div className="col-6">
                    <Form.Group controlId="accessCode" className="mb-3">
                      <Form.Label>Access Code</Form.Label>
                      <Form.Control
                        disabled={isEdit}
                        type="text"
                        placeholder="Access Code"
                        id="accessCode"
                        value={details.accessCode}
                        minLength={7}
                        maxLength={7}
                        required
                        isInvalid={valiteded && details.accessCode.length != 7}
                        onChange={(e) =>
                          handleChage(
                            "accessCode",
                            e.target.value.toUpperCase()
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter 7 Digit Valid Code!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  {/* <div className="col-3 ms-5 d-flex mt-3">
                    <Form.Check
                      type="checkbox"
                      onClick={handleClickcheck}
                    ></Form.Check>
                    {checked ? (
                      <Form.Label className="ms-3">Deactive</Form.Label>
                    ) : (
                      <Form.Label className="ms-3">Active</Form.Label>
                    )}
                  </div> */}
                  <div className="col-auto ms-5 mt-3">
                    <div className="App-header">
                      <div
                        className="d-flex "
                        onClick={() => {
                          handleChage("isActive", !details?.isActive);
                        }}
                      >
                        <div className="col-3 me-3 text-nowrap toggle-label">
                          Inactive
                        </div>
                        <div
                          className={`col-6 toggle-switch  ${
                            details?.isActive ? "active" : ""
                          }`}
                        >
                          <div className="toggle-circle"></div>
                        </div>
                        <div className="col-3 me-3 text-nowrap toggle-label">
                          Active
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*                 
                <Form.Group controlId="accessCode" className="mb-3">
                  <Form.Label>Access Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Access Code"
                    id="accessCode"
                    value={details.accessCode}
                    minLength={7}
                    maxLength={7}
                    required
                    isInvalid={valiteded && details.accessCode.length != 7}
                    onChange={(e) =>
                      handleChage("accessCode", e.target.value.toUpperCase())
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter 7 Digit Valid Code!
                  </Form.Control.Feedback>
                </Form.Group> */}
                <Form.Group controlId="reason" className="mb-3">
                  <Form.Label>Application Type </Form.Label>
                  <Form.Select
                    disabled={isEdit}
                    required
                    value={details?.reason}
                    onChange={(e) => {
                      setValiteded(false);
                      handleChage("reason", e.target.value);
                    }}
                  >
                    <option disabled value={""}>
                      Select reason
                    </option>
                    <option value="Individual User">Individual User</option>
                    <option value="Job Application">Job Application</option>
                    <option value="Internal Assessment">
                      Internal Assessment
                    </option>
                    <option value="Investor">Investor</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please Select Reason!
                  </Form.Control.Feedback>
                  {/* <Form.Control type="email" placeholder="Reason" /> */}
                </Form.Group>

                {details?.reason !== "Individual User" &&
                  details?.reason !== "Investor" &&
                  details?.reason !== "" && (
                    <>
                      <Form.Group controlId="company" className="mb-3">
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Company"
                          required
                          value={details?.company}
                          onChange={(e) =>
                            handleChage("company", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Company Name!
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* <Form.Group controlId="designation" className="mb-3">
                        <Form.Label>Designation</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Designation"
                          required
                          value={details?.designation}
                          onChange={(e) =>
                            handleChage("designation", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Designation!
                        </Form.Control.Feedback>
                      </Form.Group> */}

                      {/* 
                  <Form.Group controlId="primary profile" className="mb-3">
                    <Form.Label>Primary Profile </Form.Label>
                    <Form.Select
                      required
                      value={details?.primaryProfile}
                      onChange={(e) =>
                        handleChage("primaryProfile", e.target.value)
                      }
                    >
                      <option disabled value={""}>
                        Select Primary Profile
                      </option>
                      <option value="1">Pioneer</option>
                      <option value="2">Influencer </option>
                      <option value="3">Team Player</option>
                      <option value="4">Logical Thinker</option>

                      <option value="5">Persuader</option>
                      <option value="7">perfectionist</option>
                      <option value="8">Collaborater</option>
                      <option value="9">Accessor</option>
                      <option value="10">Implementor</option>
                      <option value="11">Motivator</option>
                      <option value="12">Leader</option>
                      <option value="13">Administrator</option>
                      <option value="14">Mediator</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Prmary Profile!
                    </Form.Control.Feedback>
                   
                  </Form.Group>

                  <Form.Group controlId="secoundry profile" className="mb-3">
                    <Form.Label>Secoundry Profile </Form.Label>
                    <Form.Select
                      required
                      value={details?.secoundryProfile}
                      onChange={(e) =>
                        handleChage("secoundryProfile", e.target.value)
                      }
                    >
                      <option disabled value={""}>
                        Select Secoundry Profile
                      </option>
                      <option value="1">Pioneer</option>
                      <option value="2">Influencer </option>
                      <option value="3">Team Player</option>
                      <option value="4">Logical Thinker</option>

                      <option value="5">Persuader</option>
                      <option value="7">perfectionist</option>
                      <option value="8">Collaborater</option>
                      <option value="9">Accessor</option>
                      <option value="10">Implementor</option>
                      <option value="11">Motivator</option>
                      <option value="12">Leader</option>
                      <option value="13">Administrator</option>
                      <option value="14">Mediator</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Prmary Profile!
                    </Form.Control.Feedback>
                  
                  </Form.Group> */}
                    </>
                  )}
                {/* {details.reason === "Internal Assessment" && (
                  <>
                    <Form.Group controlId="primary profile" className="mb-3">
                      <Form.Label>Primary Profile </Form.Label>
                      <Form.Select
                        required
                        value={details?.primaryProfile}
                        onChange={(e) =>
                          handleChage("primaryProfile", e.target.value)
                        }
                      >
                        <option disabled value={""}>
                          Select Primary Profile
                        </option>
                        <option value="1">Pioneer</option>
                        <option value="2">Influencer </option>
                        <option value="3">Team Player</option>
                        <option value="4">Logical Thinker</option>

                        <option value="5">Persuader</option>
                        <option value="7">perfectionist</option>
                        <option value="8">Collaborater</option>
                        <option value="9">Accessor</option>
                        <option value="10">Implementor</option>
                        <option value="11">Motivator</option>
                        <option value="12">Leader</option>
                        <option value="13">Administrator</option>
                        <option value="14">Mediator</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Prmary Profile!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="secoundry profile" className="mb-3">
                      <Form.Label>Secoundry Profile </Form.Label>
                      <Form.Select
                        required
                        value={details?.secoundryProfile}
                        onChange={(e) =>
                          handleChage("secoundryProfile", e.target.value)
                        }
                      >
                        <option disabled value={""}>
                          Select Secoundry Profile
                        </option>
                        <option value="1">Pioneer</option>
                        <option value="2">Influencer </option>
                        <option value="3">Team Player</option>
                        <option value="4">Logical Thinker</option>

                        <option value="5">Persuader</option>
                        <option value="7">perfectionist</option>
                        <option value="8">Collaborater</option>
                        <option value="9">Accessor</option>
                        <option value="10">Implementor</option>
                        <option value="11">Motivator</option>
                        <option value="12">Leader</option>
                        <option value="13">Administrator</option>
                        <option value="14">Mediator</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Prmary Profile!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )} */}
                {details.reason === "Job Application" && (
                  <div className="mt-3">
                    <Form.Group controlId="designation" className="mb-3">
                      <Form.Label>Designation</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Designation"
                        required
                        value={details?.designation}
                        onChange={(e) =>
                          handleChage("designation", e.target.value)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Designation!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      controlId="Primary match %"
                      className="mb-3 position-relative"
                    >
                      <div className="row justify-content-between">
                        <Form.Label>Job Access Group</Form.Label>
                        <div className="col">
                          <input
                            type="email"
                            className="form-control"
                            isInvalid={valiteded && showEmails.length}
                            placeholder="Job Access Group"
                            value={enterEmail}
                            onChange={(e) => setEnterEmail(e.target.value)}
                          />
                          {/* <Form.Control.Feedback type="invalid">
                              Please Add Company Users!
                            </Form.Control.Feedback> */}
                        </div>
                        <div className="col-2">
                          <Button className="" onClick={handleAddEmail}>
                            Add
                          </Button>
                        </div>
                      </div>

                      {
                        <div className="col-5 position-absolute start-0 mt-3">
                          {showEmails.map((email, index) => (
                            <span
                              className="addbackgroundcolor px-2 py-1 rounded-5 me-3 text-nowrap"
                              key={index}
                            >
                              {email?.email}
                              <button
                                type="button"
                                className="btn-close"
                                style={{
                                  width: "5px",
                                  height: "7px",
                                  marginLeft: "4px",
                                  opacity: "0.8",
                                  marginBottom: "-5px",
                                }}
                                onClick={() => removeEmail(index)}
                              ></button>
                              {/* <img
                                src={close}
                                style={{ width: "15px", marginLeft: "5px" }}
                                onClick={() => removeEmail(index)}
                              /> */}
                            </span>
                          ))}
                        </div>
                      }
                    </Form.Group>
                  </div>
                )}
              </div>

              <div className="col-6">
                {details.reason === "Job Application" && (
                  <>
                    {
                      <table className="group-table table table-hover">
                        <thead>
                          <tr>
                            <th>Profile</th>
                            <th>Match</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Pioneer</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                required
                                style={{ width: "65px" }}
                                value={initialProfiles?.pioneer}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles("pioneer", e.target.value);
                                  }
                                }}
                              />
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Influencer</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                style={{ width: "65px" }}
                                type="number"
                                className="me-1"
                                required
                                value={initialProfiles?.influencer}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles(
                                      "influencer",
                                      e.target.value
                                    );
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Team Player</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.teamPlayer}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles(
                                      "teamPlayer",
                                      e.target.value
                                    );
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Logical Thinker</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.logicalThinker}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles(
                                      "logicalThinker",
                                      e.target.value
                                    );
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Persuader</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.persuader}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles("persuader", e.target.value);
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Perfectionist</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.perfectionist}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles(
                                      "perfectionist",
                                      e.target.value
                                    );
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Collaborater</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.collaborator}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles(
                                      "collaborator",
                                      e.target.value
                                    );
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Achiever</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.achiever}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles("achiever", e.target.value);
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Accessor</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.assessor}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles("assessor", e.target.value);
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Implementor</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.implementor}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles(
                                      "implementor",
                                      e.target.value
                                    );
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Motivator</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.motivator}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles("motivator", e.target.value);
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Leader</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.leader}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles("leader", e.target.value);
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Administrator</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.administrator}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles(
                                      "administrator",
                                      e.target.value
                                    );
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Mediator</td>
                            <td className="d-flex justify-content-center align-items-center">
                              <Form.Control
                                type="number"
                                className="me-1"
                                style={{ width: "65px" }}
                                required
                                value={initialProfiles.mediator}
                                onChange={(e) => {
                                  if (
                                    (Number(e.target.value) <= 100 &&
                                      Number(e.target.value >= 0)) ||
                                    e.target.value === ""
                                  ) {
                                    handleProfiles("mediator", e.target.value);
                                  }
                                }}
                              ></Form.Control>
                              %
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }
                    {/* <Form.Group controlId="Primary match %" className="mb-3">
                  <Form.Label>Primary match %</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Primary match %"
                    value={details?.primaryMatch}
                    onChange={(e) =>
                      (e.target.value === "" ||
                        (Number(e.target.value) <= 100 &&
                          Number(e.target.value) > 0)) &&
                      handleChage("primaryMatch", e.target.value)
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Primary Match!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="Secondary match %" className="mb-3">
                  <Form.Label>Secondary match %</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Secondary match %"
                    required
                    value={details?.secoundryMatch}
                    onChange={(e) =>
                      (e.target.value === "" ||
                        (Number(e.target.value) <= 100 &&
                          Number(e.target.value) > 0)) &&
                      handleChage("secoundryMatch", e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Secoundry Match!
                  </Form.Control.Feedback>
                </Form.Group> */}
                  </>
                )}
              </div>
            </div>

            {/* {reason === "Job Application" && (
              <Form.Group controlId="Secondary match %" className="mb-3">
                <Form.Label>Secondary match %</Form.Label>
                <Form.Control type="text" placeholder="Secondary match %" />
              </Form.Group>
            )} */}

            {/* {reason === "Internal Assessment" && (
              <Form.Group controlId="Job profile secondary" className="mb-3">
                <Form.Label>Job profile secondary</Form.Label>
                <Form.Control type="text" placeholder="Job profile secondary" />
              </Form.Group>
            )} */}
            {/* 
            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label>Company </Form.Label>
              <Form.Control type="text" placeholder="Company" />
            </Form.Group>
            <Form.Group
              controlId="organizationOrIndividualName"
              className="mb-3"
            >
              <Form.Label>Designation</Form.Label>
              <Form.Control type="text" placeholder="Designation" />
            </Form.Group>
            <Form.Group
              controlId="organizationOrIndividualName"
              className="mb-3"
            >
              <Form.Label>Additional Qs part 1</Form.Label>
              <Form.Control type="text" placeholder="Additional Qs part 1" />
            </Form.Group>

            <Form.Group
              controlId="organizationOrIndividualName"
              className="mb-3"
            >
              <Form.Label>Additional Qs part 2</Form.Label>
              <Form.Control type="text" placeholder="Additional Qs part 2" />
            </Form.Group> */}
            <div className="d-flex justify-content-end">
              <Button className="custom-register-btn" type="submit">
                {isEdit ? "Update" : "Add"} Group
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GroupList;
