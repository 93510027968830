import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "../assets/images/search-lg-icon.svg";
import filterLines from "../assets/images/filter-lines.svg";
import downloadIcon from "../assets/images/download-icon.svg";
import trashIcon from "../assets/images/trash-icon.svg";
import plusIcon from "../assets/images/plus-icon.svg";
import threeDots from "../assets/images/dots-vertical.svg";

const InternalAssessment = () => {
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File selected:", file.name);
      // You can add further logic to handle the file upload here
    }
  };
  const [rowsToShow, setRowsToShow] = useState(10);

  const handleRowsToShowChange = (event) => {
    setRowsToShow(Number(event.target.value));
  };
  const InternalAssessmentData = [
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "Full-Stack Development",
      "Data Science",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "Project Management",
      "Structural Analysis",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "Quantum Mechanics",
      "Particle Physics",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "Data Analysis",
      "Machine Learning",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "Financial Modelling",
      "Risk Management",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "Full-Stack Development",
      "Data Science",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "Project Management",
      "Structural Analysis",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "Quantum Mechanics",
      "Particle Physics",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "Data Analysis",
      "Machine Learning",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "Financial Modelling",
      "Risk Management",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "Full-Stack Development",
      "Data Science",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "Project Management",
      "Structural Analysis",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "Quantum Mechanics",
      "Particle Physics",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "Data Analysis",
      "Machine Learning",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "Financial Modelling",
      "Risk Management",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "Full-Stack Development",
      "Data Science",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "Project Management",
      "Structural Analysis",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "Quantum Mechanics",
      "Particle Physics",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "Data Analysis",
      "Machine Learning",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "Financial Modelling",
      "Risk Management",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "Full-Stack Development",
      "Data Science",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "Project Management",
      "Structural Analysis",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "Quantum Mechanics",
      "Particle Physics",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "Data Analysis",
      "Machine Learning",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "Financial Modelling",
      "Risk Management",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "Full-Stack Development",
      "Data Science",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "Project Management",
      "Structural Analysis",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "Quantum Mechanics",
      "Particle Physics",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "Data Analysis",
      "Machine Learning",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "Financial Modelling",
      "Risk Management",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "Full-Stack Development",
      "Data Science",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "Project Management",
      "Structural Analysis",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "Quantum Mechanics",
      "Particle Physics",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "Data Analysis",
      "Machine Learning",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "Financial Modelling",
      "Risk Management",
    ],
    [
      "43 min",
      "Olivia Rhye",
      "Master's",
      "Science",
      "4",
      "TechCorp",
      "Software Engineer",
      "Technology",
      "Full-Stack Development",
      "Data Science",
    ],
    [
      "50 min",
      "Liam Wong",
      "Bachelor's",
      "Engineering",
      "6",
      "BuildIt Inc.",
      "Civil Engineer",
      "Construction",
      "Project Management",
      "Structural Analysis",
    ],
    [
      "1 hr",
      "Emma Johnson",
      "Ph.D.",
      "Physics",
      "3",
      "Innovate Labs",
      "Research Scientist",
      "Research",
      "Quantum Mechanics",
      "Particle Physics",
    ],
    [
      "38 min",
      "Noah Brown",
      "Bachelor's",
      "Information Technology",
      "5",
      "DataSolutions",
      "Data Analyst",
      "IT",
      "Data Analysis",
      "Machine Learning",
    ],
    [
      "1 hr, 3 min",
      "Ava Smith",
      "Master's",
      "Business Administration",
      "7",
      "FinanceWorld",
      "Financial Analyst",
      "Finance",
      "Financial Modelling",
      "Risk Management",
    ],
  ];

  return (
    <div className="candListBox ">
      <div className="candListHdr">
        <div className="canLisTxtStyl">
          <p>Internal Assessment</p>
        </div>
        <div className="canLisSearchArea">
          <div className="searchBox">
            <img src={SearchIcon} className="boxIcon" alt="Logo" />
            <input
              type="text"
              className="searchInput"
              placeholder="Search"
              // readOnly
            />
          </div>
          <div className="canLisIconBox">
            <img src={filterLines} className="boxIcon" alt="Logo" />
          </div>
          <div className="canLisIconBox">
            <img src={downloadIcon} className="boxIcon" alt="Logo" />
          </div>
          <div className="canLisIconBox">
            <img src={trashIcon} className="boxIcon" alt="Logo" />
          </div>
          {/* <button className="uploadBtn" onClick={handleUploadClick}>
            <img src={plusIcon} className="boxIcon" alt="Logo" />
            <p>Upload</p>
            <input
              type="file"
              className="fileInput"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept=".pdf,.doc,.docx"
            />
          </button> */}
        </div>
      </div>
      <div className="table-responsive tableWrap">
        <table className="customTable">
          <thead>
            <tr>
              <th>Date of Completion</th>
              <th>Name</th>
              <th>Educational Level</th>
              <th>Area of Education</th>
              <th>Years of Experience</th>
              <th>Current Company</th>
              <th>Current Job</th>
              <th>Industry</th>
              <th>Primary Profile</th>
              <th>Secondary Profile</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {InternalAssessmentData.slice(0, rowsToShow).map(
              (row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                  <td>
                    <img src={threeDots} className="boxIcon" alt="Logo" />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="rowSelectorArea">
        <div className="rowSelector">
          <label htmlFor="rowsToShow">Show rows:</label>
          <select
            id="rowsToShow"
            value={rowsToShow}
            onChange={handleRowsToShowChange}
          >
            {[...Array(10).keys()]
              .map((i) => (i + 1) * 10)
              .map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
          </select>
        </div>
      </div>{" "}
    </div>
  );
};

export default InternalAssessment;
