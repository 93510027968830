import React, { useContext, useEffect, useRef, useState } from "react";
import SearchIcon from "../assets/images/search-lg-icon.svg";
import filterLines from "../assets/images/filter-lines.svg";
import downloadIcon from "../assets/images/download-icon.svg";
import trashIcon from "../assets/images/trash-icon.svg";
import threeDots from "../assets/images/dots-vertical.svg";
import mediator from "../assets/images/mediator.svg";
import mediatorLetter from "../assets/images/m-mediator.svg";
import shareicon from "../assets/images/share.svg";
import influencer from "../assets/images/influencer.svg";
import Pioneer from "../assets/images/pioneer.svg";
import logicalthinker from "../assets/images/logical-thinker.svg";
import teamplayer from "../assets/images/team-player.svg";
import persuader from "../assets/images/persuader.svg";
import achiever from "../assets/images/achiever.svg";
import perfectionist from "../assets/images/perfectionist.svg";
import collaborator from "../assets/images/collaborator.svg";
import assessor from "../assets/images/accessor.svg";
import implementor from "../assets/images/implementor.svg";
import motivator from "../assets/images/motivator.svg";
import leader from "../assets/images/leader.svg";
import administrator from "../assets/images/administrator.svg";

import influencerletter from "../assets/images/i-influencer.svg";
import pioneerletter from "../assets/images/p-pioneer.svg";
import logicalthinkerletter from "../assets/images/l-logical.svg";
import teamplayerletter from "../assets/images/t-teamplayer.svg";
import persuaderletter from "../assets/images/p-persuader.svg";
import achieverletter from "../assets/images/a-achiever.svg";
import perfectionistletter from "../assets/images/p-perfectionist.svg";
import collaboratorletter from "../assets/images/c-collaborator.svg";
import assessorletter from "../assets/images/a-accessor.svg";
import implementorletter from "../assets/images/i-implementor.svg";
import motivatorletter from "../assets/images/m-motivator.svg";
import leaderletter from "../assets/images/l-leader.svg";
import administratorletter from "../assets/images/a-aadministrator.svg";
import mediatorletter from "../assets/images/m-mediator.svg";
import downloadicon from "../assets/images/download-icon.svg";

import {
  PostCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleDeleteCall,
  simpleGetCallWithErrorResponse,
} from "../Api/ApiServices";
import { ApiConfig } from "../Api/ApiConfig";
import NoDataFound from "../component/noDataFound";
import FileSaver from "file-saver";
import Moment from "react-moment";
import searchicon from "../assets/images/search-lg-icon.svg";
import { Button, Space, Table } from "antd";
import _ from "lodash";
import { render } from "@testing-library/react";
import { json } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const AccessCode = (props) => {
  const [rowsToShow, setRowsToShow] = useState(10);

  const { respondendByCode, companyDtls } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [filteredData, setFilteredData] = useState([]);

  const [downloadBtn, setDownloadBtn] = useState(false);

  const [search, setSearch] = useState("");

  const [type, setType] = useState("");

  const [code, setCode] = useState("");

  const { adminData } = useContext(AppContext);

  const [isDiasableSearch, setIsDisableSearch] = useState(false);

  const handleRowsToShowChange = (event) => {
    setRowsToShow(Number(event.target.value));
  };

  const [allData, setAllData] = useState([]);

  const [accessCodeList, setAccessCoeList] = useState([]);

  useEffect(() => {
    console.log(companyDtls);

    if (respondendByCode?.length > 0) {
      getList(respondendByCode);

      setIsDisableSearch(true);

      setCode(respondendByCode);
    }
  }, []);

  const getList = (code) => {
    //'AFB6CF'

    setIsLoading(true);
    const param1 = code?.toUpperCase();
    const param2 = false;

    const url = `${ApiConfig.ACCESS_CODE_LIST}code=${param1}&export=${param2}`;
    postWithAuthCallWithErrorResponse(url, JSON.stringify({}))
      .then((res) => {
        // setIsLoading(true)

        if (res?.json?.succeded) {
          setAllData(res?.json?.response);
          setAccessCoeList(res?.json?.response);

          setType(
            res?.json?.response[0]?.type
              ? res?.json?.response[0]?.type
              : companyDtls?.position
          );
          console.log(res.json.response.length);
        } else {
          setAccessCoeList([]);
          setAllData([]);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const filterData = (data) => (formatter) =>
    data.map((item) => ({
      text: formatter(item),
      value: formatter(item),
    }));

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChange = (pagination, filters, sorter, currentDataSource) => {
    setFilteredData(currentDataSource?.currentDataSource);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  //   const downloadExcelfile = async (userId) => {
  //     try {
  //         const response = await axios.get(`https://api-beta.lywo.in/api/Assessment/GenerateExcel/generateExcel`, {
  //             params: { UserId: userId },
  //             responseType: 'blob'
  //         });
  //     console.log(response);
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', 'PersonalityData.xlsx');
  //         document.body.appendChild(link);
  //         link.click();
  //     } catch (error) {
  //         console.error("There was an error downloading the Excel file!", error);
  //     }
  // };
  const downloadExcelfile = async (userid) => {
    try {
      console.log("Starting file download for user:", userid);
      const response = await fetch(
        `https://api-beta.lywo.in/api/Assessment/GenerateExcel/generateExcel?UserId=${userid}`,
        {
          method: "GET",
          mode: "cors",
        }
      );
      console.log("Response:", response);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      console.log("Blob created:", blob);

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PersonalityData.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
      console.log("File downloaded successfully.");
    } catch (error) {
      console.error("There was an error downloading the Excel file!", error);
    }
  };

  const downloadExcel = async (userId) => {
    try {
      const curl = `https://api-beta.lywo.in/api/Assessment/GenerateExcel/generateExcel?UserId=${userId}`;
      const response = postWithAuthCallWithErrorResponse(
        url,
        JSON.stringify({})
      );

      // const response = await fetch(`https://api-beta.lywo.in/api/Assessment/GenerateExcel/generateExcel?UserId=${userId}`, {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/json'
      //     },
      //     credentials: 'include', // Include cookies (if needed), or use 'same-origin' depending on your needs
      // });

      // if (!response.ok) {
      //     throw new Error('Network response was not ok');
      // }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PersonalityData.xlsx"); // Set the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleanup
    } catch (error) {
      console.error("There was an error downloading the Excel file!", error);
    }
  };
  const deleteRecord = (userId, value) => {
    const url = `${ApiConfig.DELETE_USER_AND_DEP}userid=${userId}`;
    simpleDeleteCall(url, userId)
      .then((res) => {
        if (res?.response?.status == true) {
          console.log("User record deleted successfully.");
          getList(value?.code);
        } else {
          console.log("Unexpected response");
        }
      })
      .catch((error) => {
        console.error("Error deleting user record:", error);
      });
  };
  // const removeUserIdFromList = (userId) => {
  //   setAllData((prevUsers) => prevUsers.filter((user) => user.id !== userId));
  //   setAccessCoeList((prevUsers) =>
  //     prevUsers.filter((user) => user.id !== userId)
  //   );
  // };

  //   const downloadExcel = async (userId) => {
  //     try {
  //         const response = await axios.get(`https://api-beta.lywo.in/api/Assessment/GenerateExcel/generateExcel`, {
  //             params: { UserId: userId },
  //             responseType: 'blob' // Important to handle the binary data
  //         });
  //     console.log(response);
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', 'PersonalityData.xlsx'); // Set the file name
  //         document.body.appendChild(link);
  //         link.click();
  //     } catch (error) {
  //         console.error("There was an error downloading the Excel file!", error);
  //     }
  // };

  function formatDate(date) {
    if (!date) return "";
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day}-${month}-${year}`;
  }
  const [perList, setPerList] = useState([
    {
      id: 1,
      img_card: influencer,
      img_letter: influencerletter,
      personality: "Influencer",
      text: "Influencers are confident, outgoing and friendly individuals who love to be the center of attention. They seek out situations that involve meeting people and socializing. Their confidence combined with a genuine interest in ideas and people, allows them to interact easily, and positively in almost any situation. These strong communicators are assertive, intuitive, and adaptive. They thrive in meetings, committees, and conferences. They may need a constant dose of Approval. They also might sometimes lose focus on timelines and the tasks at hand.",
      strengths:
        "Fun, persuasive, ideas-oriented, creative, original, networkers, intuitive",
      uniqueness: "Openness, strong communication, social confidence",
      valtoorg:
        "Contributing original ideas, improve and extend coordination within team",
      challenges: "Needs constant approval, pressured conditions",
      motivations:
        "Being surrounded by people, supportive and collaborative environment",
    },
    {
      id: 2,
      img_card: Pioneer,
      img_letter: pioneerletter,
      personality: "Pioneer",
      text: "Pioneers are often ambitious, competitive, and strong-willed individuals. They have a very high need to achieve and continually seek new horizons. Their self-reliance and independent thinking drive them to escape convention and create innovative solutions. They are direct and assertive and apt at influencing people. They are dynamic, adaptable, and willing to do anything to overcome obstacles. Pioneers are persistent in their pursuit of desired results. Overcome by their desire to achieve their goals, they might fail to take other people’s feelings into consideration. Additionally, they have high expectations of others and can be critical if their standards are not met.",
      strengths:
        "Action-oriented, resourceful, self-reliant, decisive, proactive, strong-willed, direct",
      uniqueness:
        "Sound decision making, self-motivated, excels in emergencies",
      valtoorg: "Innovative solutions; taking responsibility",
      challenges: "Too independent, tends to be blunt, boredom",
      motivations:
        "New opportunities, strong need to achieve, power and structure.",
    },
    {
      id: 3,
      img_card: logicalthinker,
      img_letter: logicalthinkerletter,
      personality: "Logical Thinker",
      text: "Logical thinkers are hardworking, logical, and detail-oriented individuals with high critical thinking abilities. Their habit of meticulous preparation, accuracy, and correctness helps them to manage tasks effectively. Despite being shy and mild-mannered, logical thinkers have a strong need to control their environment. They exert rules, structure, and standards, to accomplish their goals, in conflicting situations. Their cautiousness makes them reluctant to reveal information about themselves or their thoughts unless necessary. Being natural risk avoiders, they only undertake tasks when they are completely certain about the outcome.",
      strengths:
        "Quality control, handling detail, analyzing data, logical, avoiding the risk, building structure, being deliberate and methodical",
      uniqueness: "Accuracy; ability to think logically; building structure",
      valtoorg:
        "Completes the undertaken task; great at quality control; organizing information",
      challenges:
        "Spotting deficiencies first; controlling; lacks assertiveness",
      motivations:
        "Certainty of situation and consequences; getting things right",
    },
    {
      id: 4,
      img_card: teamplayer,
      img_letter: teamplayerletter,
      personality: "Team Player",
      text: "Team players are friendly, calm, and thoughtful individuals that like doing practical jobs. Amidst their calm nature, controlled stance, modest attitude, and excellent listening skills, they tend to empathize with others’ perspectives. They value positive interactions and are always willing to help. They depend on more socially assertive people to take the lead. Their persistence and patience enable them to work steadily until the task is completed. They value trust strongly and enjoy being part of a smaller team where they are valued and appreciated. Typically, they resist change and need help in developing alternative solutions.",
      strengths:
        "Flexibility, dependability, harmony, warmth, listening, team-focused, loyal",
      uniqueness: "Listens effectively, supportive, works steadily",
      valtoorg:
        "Capable of dealing with laborious tasks; works on a task until it is achieved",
      challenges: "Sudden change; interruptions and distractions",
      motivations:
        "Helping others succeed; support of people; doing practical jobs",
    },
    {
      img_card: persuader,
      img_letter: persuaderletter,
      personality: "Persuader",
      text: "Persuaders are assertive, adventurous, and confident individuals with strong social skills. They can be charming or demanding, based on the circumstances. They tend to approach people and situations with energy. While communicating, they can be both assertive and persuasive. Their ability to think and react quickly lets them easily adapt to challenging situations. They have no fear of confrontation and address issues directly. They have clear goals in life and achieve them with sheer determination and commitment. Persuaders thrive in situations that others would find impossibly stressful and difficult. They live for and are happiest when on adventures.",
      strengths:
        "Charming, visionary, adventurous, bold, high energy, results-focused, exciting and fun",
      uniqueness:
        "Sound decision making, self-motivated, excels in emergencies",
      valtoorg:
        "Thrives under pressure; motivates people; commits and achieves results",
      challenges: "Stagnation; can get aggressive under pressure",
      motivations: "Success and recognition; new experiences and challenges",
    },
    {
      img_card: achiever,
      img_letter: achieverletter,
      personality: "Achiever",
      text: "This is an extremely rare profile with radically different sets of values and motivations. Achievers are reliable, persistent, tenacious, and industrious individuals with a strong sense of personal accountability. They like to get things done in a friendly but thorough manner. They are focused on results with a strong inclination towards fair play and respect. Their careful planning allows them to complete tasks within realistic timescales. They tend to be thoughtful in favorable conditions and demanding at times of pressure. Achievers are usually cautious and only open up to a small set of people they completely trust. They function at peak efficiency and expect recognition equal to their contribution.",
      strengths:
        "Patient, tenacious, dependable, consistent, protective, kind, watchful",
      uniqueness: "Methodical in approach; accountability of work",
      valtoorg: "Self-driven and completes tasks effectively",
      challenges: "Poor communication; inferior work",
      motivations: "Personal and professional accomplishments",
    },
    {
      img_card: perfectionist,
      img_letter: perfectionistletter,
      personality: "Perfectionist",
      text: "Perfectionists are tenacious, competitive, systematic, and creative individuals with opposing behavioral forces. They seek immediate results and have an equally strong desire for perfection. Their ability to plan and achieve quality allows them to make sound decisions. They think and act quickly but also evaluate all options before agreeing to a course of action. In their drive for results and perfection, they demand the freedom to explore and the authority to examine. They are straightforward and enjoy working by themselves. They tend to be careful with relationships and prefer a strategic approach when dealing with others. They are open to change but extremely cautious.",
      strengths:
        "Tenacity, quality control, handling large amounts of detail, analyzing data, building systems, competing to win, results-focused, striving to be the best",
      uniqueness:
        "Attention to detail; tries innovative approaches using existing systems",
      valtoorg:
        "Can bring positive change to the team by challenging them and raising the standards",
      challenges: "Bored with routine work; gets lost in detail",
      motivations: "Unique accomplishments and dominance",
    },
    {
      img_card: collaborator,
      img_letter: collaboratorletter,
      personality: "Collaborator ",
      text: "Collaborators are warm, empathetic, easygoing and relaxed individuals that socialize with ease. They tend to think of others first over themselves. They strive to maintain harmony and build long-term relationships. Being good listeners, they make others feel heard and offer suggestions gently. They are sympathetic in nature and fulfil supportive roles well. They often provide recognition to others and attribute less importance to task accomplishment. They love to work in team settings and appreciate environments that ensure stability with minimal change. While they generally avoid conflict and confrontation, they are willing to mediate with others to restore harmony.",
      strengths:
        "Versatile, warm, responsive, upbeat, respectful, loyal, enthusiastic",
      uniqueness: "Group acceptance and approval; friendliness",
      valtoorg:
        "Effective listening skills; bring stability and harmony to the team",
      challenges:
        "Conflicting situations; over-commitment; boredom; cannot confront or give direct orders.",
      motivations:
        "Recognition from people around; peaceful environment where they can collaborate with others",
    },
    {
      img_card: assessor,
      img_letter: assessorletter,
      personality: "Assessor",
      text: "Assessors are articulate, creative, organized, and well-researched individuals with a unique blend of contradicting traits. They seek the company of others but are also exhausted by them. On one hand, they are outgoing and impulsive in favorable conditions yet in formal situations, they are precise and rule-abiding. They are naturally skilled at influencing people with strong communication. They are imaginative, perceptive and strive for perfection. They elicit cooperation from others by being considerate. They have the desire to win and can overwork to the point of exhaustion. They are practical and ensure progressive results by developing a detailed plan of action.",
      strengths:
        "Articulate, well-researched, produce quality results, self-disciplined, careful, expressive, enthusiastic, fun",
      uniqueness:
        "Turning dreams into workable concepts; result-oriented and quality-driven",
      valtoorg:
        "Brings new ideas to the table; team players that instigate action and activities",
      challenges: "Not knowing when to stop; sensitive to criticism",
      motivations: "Winning attention and approval; succeeding at all costs",
    },
    {
      img_card: implementor,
      img_letter: implementorletter,
      personality: "Implementor ",
      text: "Implementors are stable, consistent, pragmatic, and accommodating individuals that are driven by patience, accuracy, and logic. They are precise thinkers and through precautions avoid the unexpected. They prefer traditional approaches. They question assumptions and require loads of information that they can analyze when exploring alternatives and before reaching conclusion. They enjoy overcoming challenges through persistence and intense focus. They are calm and rational folks, and they rarely provide input in a group. They hold themselves to high standards and desire to earn the respect of those around them.",
      strengths:
        "Dependable, stable, careful, accurate, hard working, patient, diplomatic",
      uniqueness: "Brings stability on board; sticks to “fail safe” options",
      valtoorg:
        "Works diligently to get the job done; maintains quality and standards",
      challenges: "Reluctant to change; takes time to adapt",
      motivations: "Predictable and stable outcomes; recognition for work",
    },
    {
      img_card: motivator,
      img_letter: motivatorletter,
      personality: "Motivator",
      text: "This is an extremely rare profile with radically different sets of values and motivations. Achievers are reliable, persistent, tenacious, and industrious individuals with a strong sense of personal accountability. They like to get things done in a friendly but thorough manner. They are focused on results with a strong inclination towards fair play and respect. Their careful planning allows them to complete tasks within realistic timescales. They tend to be thoughtful in favorable conditions and demanding at times of pressure. Achievers are usually cautious and only open up to a small set of people they completely trust. They function at peak efficiency and expect recognition equal to their contribution.",
      strengths:
        "Dependable, socially skilled, patient, warm, interesting and fun, driven to achieve, self-reliant",
      uniqueness:
        "Clarity on goals and ways to accomplish; control over circumstances",
      valtoorg:
        "Confidently suggests innovative ideas; assigns tasks effectively",
      challenges:
        "Being asked to investigate too much detail; routine and regimen",
      motivations: "New opportunities; strong need to achieve",
    },
    {
      img_card: leader,
      img_letter: leaderletter,
      personality: "Leader",
      text: "Leaders are practical, innovative, self-reliant, and ambitious individuals that are always pushing to make things better. They are friendly, charming, and enthusiastic in casual circumstances but tend to be direct, forthright, and assertive in formal setups. They like taking responsibility and taking credit for both wins and losses, as long as they have control of the results. They do not shy away from taking command when needed and may be impatient with the inefficiencies of others. They are sensitive to the needs of the team. They find a balance between personal ambitions and group goals. They are very clear thinkers, make good decisions and promote harmony in the team.",
      strengths:
        "Driven, innovative, practical, self-reliant, leadership ability, confident, clear thinking",
      uniqueness: "Accommodating and inspiring; drive to make things better",
      valtoorg: "Persistently accomplishes goals with inclusivity of team",
      challenges:
        "Impatience when things don’t go a certain way; being managed",
      motivations:
        "Opportunity to get things done in their way; supporting the team",
    },
    {
      img_card: administrator,
      img_letter: administratorletter,
      personality: "Administrator ",
      text: "Administrators are steady, objective, clear-minded and analytical individuals that get results and keep the team happy at the same time. They have a clear view of their aims in life. Their patience and thoughtfulness help them avoid risks. They guard their thoughts and reveal them only after careful thought and consideration. They choose their words carefully, make practical decisions, and rely upon logic rather than emotion. They possess the drive to track detail and the desire to build the harmony needed to run the team. While being good executors, they rarely bring in the imagination and vision needed for innovative initiatives. They can be both dominant and supportive based on the situation.",
      strengths:
        "Productive, efficient, practical, pragmatic, thoughtful, detail-oriented, patient",
      uniqueness: "Uses logic effectively to get results",
      valtoorg:
        "Works with determination; can work individually or with others",
      challenges: "Tendency to get overworked; being blunt; not creative",
      motivations:
        "Achieving results by taking time to adapt to changing situations; attention to detail; avoiding risk",
    },
    {
      img_card: mediator,
      img_letter: mediatorLetter,
      personality: "Mediator",
      text: "Mediators are creative, well-spoken, and understanding individuals that enjoy being a team member and helping others succeed. They love being in a supportive role and can bring valuable insights if given the time needed to think. They are rational in problem-solving and adept at presenting arguments in a credible manner. Their insistence on taking things at their own pace might not be agreeable to all. They prefer a cooperative environment where people are trustworthy and pleasant. They thrive on their contribution to projects that require attention to detail. They have high expectations of themselves and others around them and might voice disapproval when their expectations are not met.",
      strengths:
        "Productive, well-spoken, researching, harmonious, collaborative, co-operative, detail-oriented, patient",
      uniqueness: "Accommodating and inspiring",
      valtoorg: "Skilled and proficient in problem solving; highly skilled",
      challenges:
        "Aversion to risks and being too predictable; not recognized as experts.",
      motivations:
        "Supporting and interacting with others; learning from and teaching to others.",
    },
  ]);
  const [onePer, setOnePer] = useState([]);
  const [showmodal, setShowmodal] = useState(false);
  // const [completedPercentages, setCompletedPercentages] = useState(0);
  const handleShow = (rec, per) => {
    if (rec === "N/A" || rec === null) {
      setOnePer([]);
      return;
    }
    setShowmodal(true);
    setOnePer(perList.filter((x) => x.personality === rec));
  };
  const handleCloseModal = () => setShowmodal(false);

  // const [showPerCard, setShowPerCard] = useState(false);
  // const handleShowPersonalityCard=()=>{
  //   setShowPerCard(true);
  //   setShowmodal(false);
  // }
  // const handleClosePerCard=()=> setShowPerCard(false);

  // const [showMainPerCard, setShowMainPerCard] = useState(false);
  // const handleShowMainPercard = ()=> {
  //   setShowMainPerCard(true);
  //   setShowPerCard(false);
  // }
  // const handleCloseMainpercard=()=> setShowMainPerCard(false);

  // const handleCloseAllPops=()=>{
  //   setShowmodal(false);
  //   setShowPerCard(false);
  //   setShowMainPerCard(false);
  // }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.localeCompare(b.name),
      // filters: _.uniqWith(
      //   filterData(accessCodeList)((i) => i.name),
      //   _.isEqual
      // ),
      // filterSearch: true,
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) =>
        value === ""
          ? record.name.toString().toLowerCase() === value.toLowerCase()
          : record.name.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      sorter: (a, b) => a.phoneNumber?.localeCompare(b.phoneNumber),
      // filters: _.uniqWith(
      //   filterData(accessCodeList)((i) => i.phoneNumber),
      //   _.isEqual
      // ),
      // filterSearch: true,
      filteredValue: filteredInfo.phoneNumber || null,
      onFilter: (value, record) =>
        value === ""
          ? record.phoneNumber?.toString() === value
          : record.phoneNumber?.toString().includes(value),
    },
    {
      title: "Email",
      dataIndex: "emailId",
      sorter: (a, b) => a.emailId?.localeCompare(b.emailId),
      // filters: _.uniqWith(
      //   filterData(accessCodeList)((i) => i.emailId),
      //   _.isEqual
      // ),
      // filterSearch: true,
      filteredValue: filteredInfo.emailId || null,
      onFilter: (value, record) =>
        value == ""
          ? record.emailId.toString().toLowerCase() === value.toLowerCase()
          : record.emailId
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
    },
    {
      title: "Edu Level",
      dataIndex: "educationLevel",
      sorter: (a, b) => a.educationLevel?.localeCompare(b.educationLevel),
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.educationLevel),
        _.isEqual
      ),
      filteredValue: filteredInfo.educationLevel || null,
      onFilter: (value, record) =>
        record.educationLevel === null
          ? record.educationLevel === null
          : record.educationLevel?.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Field of Education",
      dataIndex: "feildOfEducation",
      sorter: (a, b) => a.feildOfEducation?.localeCompare(b.feildOfEducation),
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.feildOfEducation),
        _.isEqual
      ),
      filteredValue: filteredInfo.feildOfEducation || null,
      onFilter: (value, record) =>
        record?.feildOfEducation === null
          ? record.feildOfEducation == null
          : record.feildOfEducation?.startsWith(value),
      filterSearch: true,
    },

    {
      title: "Year Of Exp",
      dataIndex: "experience",
      sorter: (a, b) => a.experience - b.experience,
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i?.experience),
        _.isEqual
      ),
      filteredValue: filteredInfo.experience || null,
      onFilter: (value, record) => record.experience == value,
      filterSearch: true,
    },
    {
      title: "Current Job Title",
      dataIndex: "roleName",
      sorter: (a, b) => a.roleName?.length - b.roleName?.length,
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.roleName),
        _.isEqual
      ),
      filteredValue: filteredInfo.roleName || null,
      onFilter: (value, record) =>
        record.roleName === null
          ? record.roleName === null
          : record.roleName?.startsWith(value),
      filterSearch: true,
    },

    {
      title: "Current Industry",
      dataIndex: "currentIndustry",
      sorter: (a, b) => a.currentIndustry - b.currentIndustry,
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.currentIndustry),
        _.isEqual
      ),
      filteredValue: filteredInfo.currentIndustry || null,
      onFilter: (value, record) =>
        record.currentIndustry == null
          ? record.currentIndustry == null
          : record.currentIndustry?.includes(value),
      filterSearch: true,
    },
    // {
    //   title: "Available from",
    //   dataIndex: "availableDate",
    //   sorter: (a, b) => new Date(a?.availableDate) - new Date(b?.availableDate),
    //   filters: _.uniqWith(
    //     filterData(accessCodeList)((i) => i?.availableDate?.split("T")[0]),
    //     _.isEqual
    //   ),
    //   filteredValue: filteredInfo.availableDate || null,
    //   filterSearch: true,
    //   onFilter: (value, record) =>
    //     record.availableDate ? record.availableDate.includes(value) : false,
    //   render: (text) => text?.split("T")[0],
    // },

    {
      title: "Primary Prof",
      dataIndex: "primaryName",
      sorter: (a, b) => a.primaryName?.localeCompare(b.primaryName),
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.primaryName),
        _.isEqual
      ).map((name) => name),
      filteredValue: filteredInfo.primaryName || null,
      onFilter: (value, record) => {
        if (record.primaryName) {
          return record.primaryName.startsWith(value);
        }
        return record.primaryName === null; // or handle the case where primaryName is null/undefined
      },
      filterSearch: true,
      onCell: (record, rowIndex) => {
        return {
          onClick: (e) => {
            handleShow(record.primaryName);
          },
        };
      },
    },
    {
      title: "Secondary Prof",
      dataIndex: "secondaryName",
      sorter: (a, b) => a.secondaryName?.length - b.secondaryName?.length,
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i?.secondaryName),
        _.isEqual
      ),
      filteredValue: filteredInfo.secondaryName || null,
      onFilter: (value, record) => {
        if (record.secondaryName) {
          return record.secondaryName.startsWith(value);
        }
        return record.secondaryName === null;
        // or handle the case where primaryName is null/undefined
      },
      filterSearch: true,
      onCell: (record, rowIndex) => {
        return {
          onClick: (e) => {
            handleShow(record.secondaryName, record.percentage);
          },
        };
      },
    },
    {
      title: "Primary Match",
      dataIndex: "primaryMatch",
      sorter: (a, b) => parseInt(a?.primaryMatch) - parseInt(b?.primaryMatch),
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.primaryMatch),
        _.isEqual
      ),
      filteredValue: filteredInfo.primaryMatch || null,
      onFilter: (value, record) => {
        // Convert both value and record.primaryMatch to strings for consistent comparison
        return record.primaryMatch == null
          ? record.primaryMatch === null
          : record.primaryMatch?.toString() === value?.toString();
      },
      filterSearch: true,
    },
    {
      title: "Secondary Match",
      dataIndex: "secondaryMatch",
      sorter: (a, b) => a.secondaryMatch?.length - b.secondaryMatch?.length,
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.secondaryMatch),
        _.isEqual
      ),
      filteredValue: filteredInfo.secondaryMatch || null,
      onFilter: (value, record) => record.secondaryMatch?.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Completed %",
      dataIndex: "percentage",
      sorter: (a, b) => parseInt(a.percentage) - parseInt(b.percentage),
      filters: _.uniqWith(
        filterData(accessCodeList)((i) =>
          i.percentage != null ? i?.percentage?.toString() : ""
        ),
        _.isEqual
      ),
      filteredValue: filteredInfo?.percentage || null,
      onFilter: (value, record) =>
        record?.percentage != null && record?.percentage.toString() === value,
      filterSearch: true,
    },

    {
      title: "Current Company",
      dataIndex: "currentCompany",
      sorter: (a, b) => a.currentCompany?.length - b.currentCompany?.length,
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.currentCompany),
        _.isEqual
      ),
      filteredValue: filteredInfo.currentCompany || null,
      onFilter: (value, record) =>
        record.currentCompany === null
          ? record.currentCompany === null
          : record.currentCompany?.startsWith(value),
      filterSearch: true,
    },
    {
      title: "Available from",
      dataIndex: "availableDate",
      sorter: (a, b) => new Date(a?.availableDate) - new Date(b?.availableDate),
      // filters: _.uniqWith(
      //   filterData(accessCodeList)((i) => formatDate(i?.availableDate)),
      //   _.isEqual
      // ),
      filteredValue: filteredInfo.availableDate || null,
      // filterSearch: true,
      onFilter: (value, record) =>
        record.availableDate
          ? formatDate(record.availableDate).includes(value)
          : false,
      render: (text) => formatDate(text),
    },
    {
      title: "Buy Out Option Available",
      dataIndex: "noticePeriodBuyoption",
      sorter: (a, b) => a.noticePeriodBuyoption - b.noticePeriodBuyoption,
      // filters: [
      //   { text: "Yes", value: true },
      //   { text: "No", value: false },
      // ],
      filteredValue: filteredInfo.noticePeriodBuyoption || null,
      onFilter: (value, record) => record.noticePeriodBuyoption === value,
      // filterSearch: true,
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Expected Salary",
      dataIndex: "expectedCTC",
      sorter: (a, b) => a.expectedCTC?.length - b.expectedCTC?.length,
      // filters: _.uniqWith(
      //   filterData(accessCodeList)((i) => i.expectedCTC),
      //   _.isEqual
      // ),
      filteredValue: filteredInfo.expectedCTC || null,
      onFilter: (value, record) =>
        record.expectedCTC === null
          ? record?.expectedCTC === null
          : record.expectedCTC?.startsWith(value),
      // filterSearch: true,
    },
    {
      title: "Download Resume",
      render: (record) => {
        return (
          <a
            href={record.resume}
            download
            target="_blank"
            alt=""
            rel="noopener noreferrer"
          >
            Resume
          </a>
        );
      },
    },
    {
      title: "Delete",
      render: (value, record) => {
        return (
          <img
            src={trashIcon}
            className="border-0"
            target="_blank"
            alt=""
            rel="noopener noreferrer"
            // onClick={() => downloadExcel(record.userId)}
            onClick={() => deleteRecord(value?.userId, value)}
          ></img>
        );
      },
    },
    {
      title: "Download",
      hidden: adminData?.admin !== "Admin",
      render: (value, record) => {
        return (
          <img
            src={downloadicon}
            className="border-0"
            target="_blank"
            alt=""
            rel="noopener noreferrer"
            onClick={() => downloadExcelfile(record?.userId)}
          ></img>
        );
      },
    },
    {
      title: "Date of Completion",
      render: (record) => record,
      dataIndex: "dateOfCompletion",
      sorter: (a, b) => {
        a.dateOfCompletion =
          a.dateOfCompletion === null ? "" : a.dateOfCompletion;
        b.dateOfCompletion =
          b.dateOfCompletion === null ? "" : b.dateOfCompletion;

        return a?.dateOfCompletion.localeCompare(b?.dateOfCompletion);
      },
      // filters: _.uniqWith(
      //   filterData(accessCodeList)((i) => i.dateOfCompletion),
      //   _.isEqual
      // ),
      filteredValue: filteredInfo.dateOfCompletion || null,
      // filterSearch: true,
      onFilter: (value, record) => record.dateOfCompletion?.startsWith(value),
    },
  ].filter((i) => i?.hidden != true);
  const column1 = [
    // {
    //   title: "Date of Completion",
    //   render: (record) => record?.dateOfCompletion,
    // },
    // {
    //   title: "Date of Completion",
    //   render: (record) => record,
    //   dataIndex: "dateOfCompletion",
    //   sorter: (a, b) => {
    //     a.dateOfCompletion =
    //       a.dateOfCompletion === null ? "" : a.dateOfCompletion;
    //     b.dateOfCompletion =
    //       b.dateOfCompletion === null ? "" : b.dateOfCompletion;

    //     return a?.dateOfCompletion.localeCompare(b?.dateOfCompletion);
    //   },
    //   filters: _.uniqWith(
    //     filterData(accessCodeList)((i) => i.dateOfCompletion),
    //     _.isEqual
    //   ),
    //   filteredValue: filteredInfo.dateOfCompletion || null,
    //   filterSearch: true,
    //   onFilter: (value, record) => record.dateOfCompletion?.startsWith(value),
    // },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name?.localeCompare(b.name),
      // filters: _.uniqWith(
      //   filterData(accessCodeList)((i) => i.name),
      //   _.isEqual
      // ),
      // filterSearch: true,
      filteredValue: filteredInfo.name || null,
      onFilter: (value, record) =>
        value === ""
          ? record.name === ""
          : record.name.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      sorter: (a, b) => a.phoneNumber?.localeCompare(b.phoneNumber),
      // filters: _.uniqWith(
      //   filterData(accessCodeList)((i) => i.phoneNumber),
      //   _.isEqual
      // ),
      // filterSearch: true,
      filteredValue: filteredInfo.phoneNumber || null,
      onFilter: (value, record) =>
        value === ""
          ? record.phoneNumber === value
          : record.phoneNumber
              ?.toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
    },
    {
      title: "Email",
      dataIndex: "emailId",
      sorter: (a, b) => a.emailId?.localeCompare(b.emailId),
      // filters: _.uniqWith(
      //   filterData(accessCodeList)((i) => i.emailId),
      //   _.isEqual
      // ),
      // filterSearch: true,
      filteredValue: filteredInfo.emailId || null,
      onFilter: (value, record) =>
        record.emailId.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Edu Level",
      dataIndex: "educationLevel",
      sorter: (a, b) => a.educationLevel?.localeCompare(b.educationLevel),
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.educationLevel),
        _.isEqual
      ),
      filteredValue: filteredInfo.educationLevel || null,
      onFilter: (value, record) =>
        record?.educationLevel === null
          ? record?.educationLevel === null
          : record.educationLevel?.includes(value),
      filterSearch: true,
      hidden: type === "Investor" || type === "Individual User",
    },
    {
      title: "Field of Education",
      dataIndex: "feildOfEducation",
      sorter: (a, b) => a.feildOfEducation?.localeCompare(b.feildOfEducation),
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.feildOfEducation),
        _.isEqual
      ),
      filteredValue: filteredInfo.feildOfEducation || null,
      onFilter: (value, record) =>
        record.feildOfEducation == null
          ? record.feildOfEducation === null
          : record.feildOfEducation?.startsWith(value),
      filterSearch: true,
      hidden: type === "Investor" || type === "Individual User",
    },
    {
      title: "Year Of Exp",
      dataIndex: "experience",
      sorter: (a, b) => a.experience - b.experience,
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i?.experience),
        _.isEqual
      ),
      filteredValue: filteredInfo.experience || null,
      onFilter: (value, record) => record.experience == value,
      filterSearch: true,
      hidden: type != "Internal Assessment",
    },
    {
      title: "Current Job Title",
      dataIndex: "roleName",
      sorter: (a, b) => a.roleName?.length - b.roleName?.length,
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.roleName),
        _.isEqual
      ),
      filteredValue: filteredInfo.roleName || null,
      onFilter: (value, record) =>
        record.roleName === null
          ? record.roleName === null
          : record.roleName?.startsWith(value),
      filterSearch: true,
      hidden: type === "Investor" || type === "Individual User",
    },

    {
      title: "Primary Prof",
      dataIndex: "primaryName",
      sorter: (a, b) => a.primaryName?.localeCompare(b.primaryName),
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.primaryName),
        _.isEqual
      ).map((name) => name),
      filteredValue: filteredInfo.primaryName || null,
      onFilter: (value, record) => {
        if (record.primaryName) {
          return record.primaryName.startsWith(value);
        }
        return false;
        // or handle the case where primaryName is null/undefined
      },
      filterSearch: true,
      onCell: (record, rowIndex) => {
        return {
          onClick: (e) => {
            handleShow(record.primaryName, record.percentage);
          },
        };
      },
    },
    {
      title: "Secondary Prof",
      dataIndex: "secondaryName",
      sorter: (a, b) => a.secondaryName?.length - b.secondaryName?.length,
      filters: _.uniqWith(
        filterData(accessCodeList)((i) => i.secondaryName),
        _.isEqual
      ),
      filteredValue: filteredInfo.secondaryName || null,
      onFilter: (value, record) => record.secondaryName?.startsWith(value),
      filterSearch: true,
      onCell: (record, rowIndex) => {
        return {
          onClick: (e) => {
            handleShow(record.secondaryName, record.percentage);
          },
        };
      },
    },
    {
      title: "Completed %",
      dataIndex: "percentage",
      sorter: (a, b) => parseInt(a.percentage) - parseInt(b.percentage),
      filters: _.uniqWith(
        filterData(accessCodeList)((i) =>
          i.percentage != null ? i.percentage.toString() : ""
        ),
        _.isEqual
      ),
      filteredValue: filteredInfo.percentage || null,
      onFilter: (value, record) =>
        record.percentage != null && record.percentage.toString() === value,
      filterSearch: true,
    },
  ].filter((i) => i?.hidden != true);

  const paginationConfig = {
    pageSizeOptions: [
      "10",
      "20",
      "30",
      "40",
      "50",
      "60",
      "70",
      "80",
      "90",
      "100",
    ],
    defaultPageSize: 50,
    showSizeChanger: false,
    onChange: (page, pageSize) => {
      console.log("Page:", page, "PageSize:", pageSize);
    },
  };

  const downLoadList = () => {
    if (code.length == 0 || downloadBtn) return;

    const mails = [];

    if (search.length > 0 || filteredData.length == 0) {
      accessCodeList.forEach((i) => {
        mails.push(i?.emailId);
      });
    } else {
      filteredData.forEach((i) => {
        mails.push(i?.emailId);
      });
    }

    filteredInfo.emailId = mails;
    filteredInfo.dateOfCompletion = null;

    setDownloadBtn(true);
    const param1 = code;
    const param2 = true;

    const url = `${ApiConfig.ACCESS_CODE_LIST}code=${param1}&export=${param2}`;
    console.log(filteredInfo);

    PostCallWithErrorResponse(url, filteredInfo)
      .then((res) => {
        if (res?.json?.status) {
          let data = res?.json;

          FileSaver.saveAs(
            "data:" + data?.contentType + ";base64," + data.data,
            data.fileName
          );
        } else {
          console.log(res);
        }

        setDownloadBtn(false);
      })
      .catch((err) => {
        console.log(err);

        setDownloadBtn(false);
      });
  };

  const filterDataByAll = (val) => {
    val = val.toLowerCase();

    let tr = val == "yes" ? true : val == "no" ? false : "";
    if (val.length > 0) {
      const fltr = allData?.filter((i) => {
        return (
          i?.name?.toLowerCase().includes(val) ||
          i?.emailId?.toLowerCase().includes(val) ||
          i?.phoneNumber?.includes(val) ||
          i?.educationLevel?.toLowerCase().includes(val) ||
          i?.feildOfEducation?.toLowerCase().includes(val) ||
          i?.roleName?.toLowerCase().includes(val) ||
          i?.currentCompany?.toLowerCase().includes(val) ||
          i?.currentIndustry?.toLowerCase().includes(val) ||
          i?.expectedCTC?.toLowerCase().includes(val) ||
          i?.percentage?.includes(val) ||
          i?.primaryName?.toLowerCase().includes(val) ||
          i?.secondaryName?.toLowerCase().includes(val) ||
          i?.primaryMatch?.includes(val) ||
          i?.secondaryMatch?.includes(val) ||
          i?.dateOfCompletion?.includes(val) ||
          i?.availableDate?.includes(val) ||
          i?.noticePeriodBuyoption === tr
        );
      });

      setAccessCoeList(fltr);
    } else {
      setAccessCoeList(allData);
    }
  };

  const filterByProfile = (type, val) => {
    if (val === "") {
      filterDataByAll(search);
      return;
    }

    if (type === "pri") {
      let filt = allData.filter((i) => {
        return i.primaryMatch == val + "%";
      });

      setAccessCoeList(filt);
    } else if (type == "sec") {
      let filt = allData.filter((i) => {
        return i.secondaryMatch == val + "%";
      });

      setAccessCoeList(filt);
    } else if (type == "both") {
      let filt = allData.filter((i) => {
        return i.secondaryMatch == val + "%" && i.primaryMatch == val + "%";
      });

      setAccessCoeList(filt);
    }
  };

  return (
    <div className="candListBox">
      <div className="candListHdr">
        {/* <div className="canLisTxtStyl"><p>Respondent Data</p></div> */}
        <div>{/* <p className="mb-0">Access Code</p> */}</div>
        {code?.length > 0 ? (
          <div className="canLisSearchArea ">
            <div>
              {/* <h5>{code}</h5> */}
              {/* <img src={SearchIcon} className="boxIcon" alt="Logo" />
            <input
              type="text"
              disabled={isDiasableSearch}
              className="searchInput"
              placeholder="Search"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                getList(e.target.value);
              }}
              // readOnly
            /> */}
            </div>
            <div className="d-flex  flex-column">
              <h5>{code}</h5>
              <p>{companyDtls?.position}</p>
            </div>
            <div className="d-flex  flex-column">
              <h5>{companyDtls?.company}</h5>
              <p>{companyDtls?.designation}</p>
            </div>
            {/* <h5>{`${companyDtls?.position}${
              companyDtls?.designation?.length > 0 ? ", " : ""
            }${companyDtls?.designation}${
              companyDtls?.company?.length > 0 ? ", " : ""
            } ${companyDtls?.company}  `}</h5> */}
            <div className="d-flex">
              {/* <h5 className="mx-1">LYWO{","}</h5> */}
            </div>
            <div className="canLisIconBox ms-5">
              <input
                type="text"
                className="border-0 search-input"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  filterDataByAll(e.target.value);
                }}
              />
              <img src={searchicon} className="boxIcon" alt="Logo" />
            </div>

            {/* <div className="canLisIconBox">
            <img src={filterLines} className="boxIcon" alt="Logo" />
          </div> */}
            <div className="canLisIconBox me-auto" onClick={downLoadList}>
              <img src={downloadIcon} className="boxIcon" alt="Logo" />
            </div>
            <div className="d-flex flex-column me-5">
              <h5>Filter Based on personality Match</h5>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column" style={{ width: "5rem" }}>
                  <label>Pr. Only</label>
                  <input
                    type="number"
                    onChange={(e) => filterByProfile("pri", e.target.value)}
                  ></input>
                </div>
                <div className="d-flex flex-column" style={{ width: "5rem" }}>
                  <label>Sec. Only</label>
                  <input
                    type="number"
                    onChange={(e) => filterByProfile("sec", e.target.value)}
                  ></input>
                </div>
                <div className="d-flex flex-column" style={{ width: "5rem" }}>
                  <label>Both</label>
                  <input
                    type="number"
                    onChange={(e) => filterByProfile("both", e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h6 className="text-secondary">
            No data available for display, process is to select Access Code to
            see responded data
          </h6>
        )}
      </div>

      {code?.length > 0 && (
        <div>
          {type === "Job Application" ? (
            <Table
              size="large"
              columns={columns}
              dataSource={accessCodeList}
              onChange={handleChange}
              pagination={paginationConfig}
              className="grouplisttable"
            />
          ) : (
            <Table
              size="large"
              columns={column1}
              dataSource={accessCodeList}
              onChange={handleChange}
              pagination={paginationConfig}
              className="grouplisttable"
            />
          )}
        </div>
      )}
      <Modal
        show={showmodal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal"
        size="lg"
        centered
      >
        <Modal.Header closeButton className="custom-close-btn"></Modal.Header>
        <Modal.Body>
          <div className="row">
            {onePer.map((personality, index) => (
              <div key={index} className="col border rounded-3 mx-2">
                <div className="row">
                  <div className="col-12" style={{ background: "#FFE6D5" }}>
                    <div className="row justify-content-between align-items-center mt-5">
                      <div className="col-6">
                        <img src={personality.img_card} alt="card-image"></img>
                      </div>
                      <div className="col-6">
                        <div className="row flex-column align-items-center">
                          <div className="col-6 d-flex justify-content-center ">
                            <img
                              width={50}
                              src={personality.img_letter}
                              alt="card-image-letter"
                            />
                          </div>
                          <div className="col-12">
                            <p
                              className="text-center"
                              style={{
                                fontSize: "48px",
                                fontWeight: "600",
                                lineHeight: "60px",
                              }}
                            >
                              {personality.personality}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row justify-content-end">
                      <div className="col-12 my-4 d-flex justify-content-start">
                        <img
                          className="me-2"
                          width={40}
                          src={personality.img_letter}
                          alt="personality-letter"
                        />
                        {/* <p style={{fontSize:"30px", fontWeight:"600"}} className="my-auto">Mediator</p> */}
                        <h2
                          className="my-auto"
                          style={{ fontSize: "25px", fontWeight: "600" }}
                        >
                          {personality.personality}
                        </h2>
                      </div>
                      <div className="col-10 mx-auto">
                        <p style={{ fontSize: "12px" }}>{personality.text}</p>
                        <hr />
                      </div>
                      <div className="col-10 mx-auto mb-5">
                        <div className="row">
                          <div className="col-2">
                            <p style={{ fontSize: "12px" }} className="">
                              <strong>Strengths</strong>
                            </p>
                          </div>
                          <div className="col">
                            <p style={{ fontSize: "12px" }}>
                              {personality.strengths}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-2">
                            <p style={{ fontSize: "12px" }} className="">
                              <strong>Uniqueness</strong>
                            </p>
                          </div>
                          <div className="col">
                            <p style={{ fontSize: "12px" }}>
                              {personality.uniqueness}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-2">
                            <p
                              style={{ fontSize: "12px" }}
                              className="text-nowrap"
                            >
                              <strong>Value to Org.</strong>
                            </p>
                          </div>
                          <div className="col">
                            <p style={{ fontSize: "12px" }}>
                              {personality.valtoorg}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-2">
                            <p
                              style={{ fontSize: "12px" }}
                              className="text-nowrap"
                            >
                              <strong>Challanges</strong>
                            </p>
                          </div>
                          <div className="col">
                            <p style={{ fontSize: "12px" }}>
                              {personality.challenges}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-2">
                            <p style={{ fontSize: "12px" }} className="">
                              <strong>Motivations</strong>
                            </p>
                          </div>
                          <div className="col">
                            <p style={{ fontSize: "12px" }}>
                              {personality.motivations}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        {/* <Modal.Header closeButton className="custom-close-btn">
        </Modal.Header>
      <Modal.Body>
        <div className="row">

        <div className="col p-3 border rounded-3">
                            <div className="">
                                 <div className="d-flex px-3">
                                    <h5 style={{fontSize:"20px", fontWeight:"700", lineHeight:"30px"}} className="me-2">78%</h5>
                                    <p>----------------------</p>
                                </div>
                             <div className="d-flex align-items-center ms-2" style={{cursor:"pointer"}} onClick={handleShowPersonalityCard}>
                                <img className="me-2" width={40} src={mediatorLetter}/>
                                <h2 className="mb-0" style={{fontSize:"20px", fontWeight:"700"}}>Mediator</h2>
                             </div>
                            </div>
                </div>
        </div>
      </Modal.Body> */}
      </Modal>

      {/* <Modal
        show={showPerCard}
        onHide={handleClosePerCard}
        dialogClassName="custom-modal"
        size="sm"
        centered
      >
        <Modal.Header closeButton className="custom-close-btn"></Modal.Header>

        <Modal.Body>
          <div className="col p-3 border rounded-3">
            <div className="">
              <div className="d-flex px-3">
                <h5
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "30px",
                  }}
                  className="me-2"
                >
                  78%
                </h5>
                <p>----------------------</p>
              </div>
              <div className="d-flex align-items-center">
                <img className="me-2" src={mediatorLetter} />
                <h2
                  className="mb-0"
                  style={{ fontSize: "20px", fontWeight: "700" }}
                >
                  Mediator
                </h2>
              </div>
              <p className="px-3 py-3" style={{ fontSize: "12px" }}>
                A well-spoken, detail-oriented, productive, and friendly
                individual who is easy to collaborate with. Has the patience to
                find the best possible solution which is both creative and
                efficient.
              </p>
              <img src={shareicon} onClick={handleShowMainPercard} />
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal
        // show={showMainPerCard}
        // onHide={handleCloseMainpercard}
        size="lg"
        centered
      ></Modal>

      {/* 
      {isLoading ? (
        <div></div>
      ) : accessCodeList?.length > 0 ? (
        <>
          <div className="table-responsive tableWrap">
            <table className="customTable">
              <thead>
                <tr>
                  {type === "Job Application" ? (
                    <>
                      <th>Date of Completion</th>
                      <th>Name</th>
                      <th>Education Level</th>
                      <th>Field of Education</th>
                      <th>Current Job Title</th>
                      <th>Current Company</th>
                      <th>Current Industry</th>
                      <th>Available from</th>
                      <th>
                        Buy Out <br /> Option Available
                      </th>
                      <th>Expected Salary</th>
                      <th>Completed %</th>
                      <th>Primary Profile</th>
                      <th>Secondary Profile</th>
                      <th>Primary Match</th>
                      <th>Secondary Match</th>
                    </>
                  ) : (
                    <>
                      <th>Date of Completion</th>
                      <th>Name</th>
                      <th>Education Level</th>
                      <th>Field of Education</th>
                      <th>Years of Experience</th>
                      <th>Current Job Title</th>
                      <th>Completed %</th>
                      <th>Primary Profile</th>
                      <th>Secondary Profile</th>
                    </>
                  )}

                  {/* <th>Name</th>
              <th>Education Level</th>
              <th>Field of Education</th>
              <th>Years <br/> of Experience</th>
              <th>Current Company</th>
              <th>Current Job</th>
              <th>Current Industry</th>
              <th>Available from</th>
              <th>Notice Period</th>
              <th>Buy Out <br/> Option Available</th>
              <th>Expected Salary</th>
              <th>Primary Profile</th>
              <th>Secondary Profile</th>
              <th>Primary Match</th>
              <th>Secondary Match</th> */}
      {/* </tr>
              </thead>
              <tbody>
                {accessCodeList?.length > 0
                  ? accessCodeList
                      .slice(
                        0,
                        rowsToShow < accessCodeList?.length
                          ? rowsToShow
                          : Number(accessCodeList?.length)
                      )
                      .map((list, rowIndex) => (
                        <tr key={rowIndex}>
                          {type === "Job Application" ? (
                            <>
                              <td>{list?.dateOfCompletion}</td>
                              <td>{list?.name}</td>
                              <td>{list?.educationLevel}</td>
                              <td>{list?.feildOfEducation}</td>
                              <td>{list?.roleName}</td>
                              <td>{list?.currentCompany}</td>
                              <td>{list?.currentIndustry}</td>
                              <td>
                                <Moment format="DD-MM-YYYY">
                                  {list?.availableDate}
                                </Moment>
                              </td>
                              <td>
                                {list?.noticePeriodBuyoption ? "Yes" : "No"}
                              </td>
                              <td>{list?.expectedCTC}</td>
                              <td>{list?.percentage}%</td>
                              <td>{list?.primaryName}</td>
                              <td>{list?.secondaryName}</td>

                              <td>{list?.primaryMatch}%</td>
                              <td>{list?.secondaryMatch}%</td>
                            </>
                          ) : (
                            <>
                              <td>{list?.dateOfCompletion}</td>
                              <td>{list?.name}</td>
                              <td>{list?.educationLevel}</td>
                              <td>{list?.feildOfEducation}</td>
                              <td>{list?.experience}</td>
                              <td>{list?.roleName}</td>
                              <td>{list?.percentage}%</td>
                              <td>{list?.primaryName}</td>
                              <td>{list?.secondaryName}</td>
                            </>
                          )}
                        </tr>
                      ))
                  : null}
              </tbody>
            </table>
          </div>

          <div className="rowSelectorArea">
            <div className="rowSelector">
              <label htmlFor="rowsToShow">Show rows:</label>
              <select
                id="rowsToShow"
                value={rowsToShow}
                onChange={handleRowsToShowChange}
              >
                {[...Array(10).keys()]
                  .map((i) => (i + 1) * 10)
                  .map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </>
      ) : (
        code?.length >= 6 && <NoDataFound />
      )} */}
    </div>
  );
};

export default AccessCode;
