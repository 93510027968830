import React, { useState, useEffect } from "react";

import { AppContext } from "./AppContext";
const AppState = (props) => {
  const [isLogin, setIsLogin] = useState(false);

  let adminInitialData = {
    userId: "",
    auth_token: "",
    email: "",
    admin: "",
  };

  const [adminData, setAdminData] = useState(adminInitialData);

  useEffect(() => {
    console.log(localStorage.getItem("userId"));

    setAdminData((prev) => ({
      ...prev,
      userId: localStorage.getItem("userId")
        ? JSON.parse(localStorage.getItem("userId"))
        : null,
      auth_token: localStorage.getItem("auth_token")
        ? localStorage.getItem("auth_token")
        : null,
      admin: localStorage.getItem("admin")
        ? JSON.parse(localStorage.getItem("admin"))
        : null,
      email: localStorage.getItem("email")
        ? JSON.parse(localStorage.getItem("email"))
        : null,
    }));

    setIsLogin(localStorage.getItem("userId") ? true : false);
  }, []);

  return (
    <div>
      <AppContext.Provider
        value={{
          isLogin,
          setIsLogin,
          adminData,
          setAdminData,
          adminInitialData,
        }}
      >
        {props.children}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;
